import React, { Suspense, lazy } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  BrandLogoLobbyIcon,
  CircleAlertIcon,
  CircleTickIcon,
} from 'components/ui-kit/Icons/svg/index';
import { LocalizedNavLink } from 'components/common/LocalizedLinks';
import { ROUTE_PATHS } from 'constants/index';
import './notFound.scss';
const Loader = lazy(() => import('components/ui-kit/Loader/index'));

const NotFoundComponent = () => {
  const { t } = useTranslation();
  const { showSideNav } = useSelector((state) => state?.settings);

  return (
    <Suspense fallback={<Loader />}>
      <div
        className={`static-root page-not-found bg-container ${
          showSideNav ? 'show' : 'false'
        }`}
      >
        <div className="back-wrapper">
          <div className="logo">
            <BrandLogoLobbyIcon />
          </div>
          <div className="sep" />
          <div style={{ marginBottom: '16px' }}>
            <CircleAlertIcon />
          </div>
          <h1>{t('404Error')}</h1>
          <p>{t('notFoundText')}</p>
          <LocalizedNavLink
            to={ROUTE_PATHS.HOME}
            className="btn btn-secondary text-uppercase mt-2"
          >
            {t('returnHome')}
          </LocalizedNavLink>
        </div>
      </div>
    </Suspense>
  );
};
export default React.memo(NotFoundComponent);
