import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGtmService } from 'network/services/gtm.service';
import { setGtm } from '../redux/slices/gtm.slice';
export const getGtm = createAsyncThunk('gtm', async (data, thunkApi) => {
  try {
    const res = await getGtmService(data);
    thunkApi.dispatch(setGtm(res));
    return res;
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description);
  }
});
