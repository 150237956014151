import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInputField from 'components/ui-kit/PhoneInput/index';

import { userBetaSignUp } from 'redux-thunk/thunk/auth.thunk';
import { openErrorToaster } from 'helpers/toaster.helpers';
import ErrorMessage from 'components/layout/ErrorMessage/index';
import Loader from 'components/ui-kit/Loader/index';
import { useQuery } from 'hooks/useQuery';
import { LOADER_HANDLER_TYPES, ROUTE_PATHS } from 'constants/index';
import { XIcon, TelegramIcon } from 'components/ui-kit/Icons/svg/index';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
const BetaFilled = ({ setShowBetaThankYouPopup, showSignupPopup }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useLocalizedNavigate();
  const query = useQuery();
  const location = useLocation();
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(
    (state) => state.loader
  );

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t('signupErrorsEmailRequired'))
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        t('signupErrorsEmailPattern')
      ),
    firstName: yup.string().required(`${t('requiredField')}`),
    lastName: yup.string().required(`${t('requiredField')}`),
    // phone: yup
    //   .string()
    //   .nullable()
    //   // .required(t('signupErrorsPhoneRequired'))
    //   .test('phone', 'Enter valid phone', function (value: any) {
    //     if (value) {
    //       if (isValidPhoneNumber(value?.toString())) {
    //         return true;
    //       }
    //       if (value.length < 4) {
    //         return true;
    //       }
    //       return false;
    //     }
    //     return true;
    //   }),
    telegram: yup
      .string()
      .matches(/^\w+$/, {
        message: t('signupErrorsUserNamePattern'),
        excludeEmptyString: true,
      })
      .nullable(),
    twitter: yup
      .string()
      .matches(/^\w+$/, {
        message: t('signupErrorsUserNamePattern'),
        excludeEmptyString: true,
      })
      .nullable(),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const handleRegisterSubmit = async (userDetails) => {
    // const { phone, ...rest } = userDetails;
    // const parsedPhone = parsePhoneNumber(phone);
    const newValue = {
      ...userDetails,
      // phone: +parsedPhone?.nationalNumber,
      // phoneCode: +parsedPhone?.countryCallingCode,
    };

    try {
      dispatch(
        userBetaSignUp({ userDetails: newValue, setShowBetaThankYouPopup })
      );
      reset();
    } catch (e) {
      openErrorToaster({ message: e.message });
    }
  };

  useEffect(() => {
    return () => {
      const langCode = location.pathname.substring(0, 3);
      const purePathname = location.pathname.replace(langCode, '');
      if (purePathname === ROUTE_PATHS.SIGNUP) {
        push(ROUTE_PATHS.HOME);
      }
    };
  }, []);

  return (
    <div
      className={`tab-pane fade ${showSignupPopup && 'show active'}`}
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <div className="register-wrap pb-5 pb-sm-0">
        <div className="auth-hint-text mb-3">
          <p>{t('betaFilledText')}</p>
        </div>
        <form onSubmit={handleSubmit(handleRegisterSubmit)}>
          <div className="half">
            <div className="mb-3 form-group">
              <label htmlFor="firstName" className="form-label text-uppercase">
                {t('firstName')}
              </label>
              <span className="input-wrapper">
                <input
                  type="text"
                  placeholder={t('firstName')}
                  className={`form-control form-control-dark shadow-none ${
                    errors?.firstName ? 'error-input' : ''
                  }`}
                  id="firstName"
                  name="firstName"
                  {...register('firstName')}
                  aria-describedby=""
                  maxLength={50}
                />
              </span>
              {errors && errors.firstName && (
                <div className="error-container">
                  <ErrorMessage
                    className="color-red text-danger error-msg login-input ms-2 d-flex"
                    message={errors.firstName.message}
                  />
                </div>
              )}
            </div>

            <div className="mb-3 form-group">
              <label htmlFor="lastName" className="form-label text-uppercase">
                {t('lastName')}
              </label>
              <span className="input-wrapper">
                <input
                  type="text"
                  placeholder={t('lastName')}
                  className={`form-control form-control-dark shadow-none ${
                    errors?.lastName ? 'error-input' : ''
                  }`}
                  id="lastName"
                  name="lastName"
                  {...register('lastName')}
                  aria-describedby=""
                  maxLength={50}
                />
              </span>
              {errors && errors.lastName && (
                <div className="error-container">
                  <ErrorMessage
                    className="color-red text-danger error-msg login-input ms-2 d-flex"
                    message={errors.lastName.message}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="full">
            <div className="mb-3 form-group">
              <label htmlFor="email" className="form-label text-uppercase">
                {t('email')}
              </label>{' '}
              <span className="color-red">*</span>
              <input
                type="email"
                placeholder={t('emailPlaceholder')}
                className={`form-control form-control-dark shadow-none ${
                  errors?.email ? 'error-input' : ''
                }`}
                id="email"
                aria-describedby=""
                name="email"
                maxLength={50}
                {...register('email')}
              />
              {errors && errors.email && (
                <div className="error-container">
                  <ErrorMessage
                    className="color-red text-danger error-msg login-input ms-2 d-flex"
                    message={errors.email.message}
                  />
                </div>
              )}
            </div>
          </div>

          {/* <div className="full">
            <div className="mb-3 form-group">
              <PhoneInputField
                label={t('phone')}
                name={t('phone')}
                id={t('phone')}
                control={control}
                theme="dark"
              />
            </div>
          </div> */}

          <div className="half">
            <div className="mb-3 form-group">
              <label htmlFor="telegram" className="form-label text-uppercase">
                {t('telegram')}
              </label>
              <span className="input-wrapper input-wrapper--withIcon">
                <span className="input-icon">
                  <TelegramIcon />
                </span>
                <input
                  type="text"
                  placeholder={t('telegramPlaceholder')}
                  className={`form-control form-control-dark shadow-none ${
                    errors?.telegram ? 'error-input' : ''
                  }`}
                  id="telegram"
                  name="telegram"
                  {...register('telegram')}
                  aria-describedby=""
                  maxLength={50}
                />
              </span>
              {errors && errors.telegram && (
                <div className="error-container">
                  <ErrorMessage
                    className="color-red text-danger error-msg login-input ms-2 d-flex"
                    message={errors.telegram.message}
                  />
                </div>
              )}
            </div>

            <div className="mb-3 form-group">
              <label htmlFor="telegram" className="form-label text-uppercase">
                {t('twitterX')}
              </label>
              <span className="input-wrapper input-wrapper--withIcon">
                <span className="input-icon">
                  <XIcon />
                </span>
                <input
                  type="text"
                  placeholder={t('twitterPlaceholder')}
                  className={`form-control form-control-dark shadow-none ${
                    errors?.twitter ? 'error-input' : ''
                  }`}
                  id="twitter"
                  name="twitter"
                  {...register('twitter')}
                  aria-describedby=""
                  maxLength={50}
                />
              </span>
              {errors && errors.twitter && (
                <div className="error-container">
                  <ErrorMessage
                    className="color-red text-danger error-msg login-input ms-2 d-flex"
                    message={errors.twitter.message}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="mb-3 form-group">
            <label
              htmlFor="referred_code"
              className="form-label text-uppercase"
            >
              {t('ReferredCode')}
            </label>
            <input
              type="text"
              placeholder={t('sixDigitCode')}
              className={`form-control form-control-dark shadow-none ${
                errors?.affiliatedBy ? 'error-input' : ''
              }`}
              id="affiliate_code"
              aria-describedby=""
              name="affiliatedBy"
              maxLength={50}
              {...register('affiliatedBy')}
              defaultValue={query.get('affiliateCode')}
              required={false}
            />
            {errors && errors.affiliatedBy && (
              <div className="error-container">
                <ErrorMessage
                  className="color-red text-danger error-msg login-input ms-2 d-flex"
                  message={errors.affiliatedBy.message}
                />
              </div>
            )}{' '}
          </div>

          <div className="auth-btn-wrap auth-btn-wrap--betaFilled mb-0">
            <button
              type="submit"
              className="btn btn-secondary w-100"
              disabled={loading}
            >
              {loading ? (
                <Loader variant={LOADER_HANDLER_TYPES.submit} />
              ) : (
                <span>{t('signUp')}</span>
              )}
            </button>
          </div>
        </form>
        <div className="auth-mob-instructions auth-mob-instructions--betaFilled accordion pb-5 pb-md-3">
          <Trans>
            <p>
              {t('followClutchOn')}
              <a href="#">
                <XIcon />
              </a>
              <br />
              {t('forMoreUpdatesAndAnnouncements')}
            </p>
          </Trans>
        </div>
      </div>
    </div>
  );
};

export default BetaFilled;
