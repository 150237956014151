import { useLocation } from 'react-router-dom';
import { withLanguage } from 'helpers/url.helpers';

export const useLocalizedActive = (to) => {
  const location = useLocation();
  const localizedPath = withLanguage(to);

  // Handle exact matches
  if (localizedPath === location.pathname) {
    return true;
  }

  // Handle nested routes
  if (localizedPath !== '/' && location.pathname.startsWith(localizedPath)) {
    return true;
  }

  return false;
};
