import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useThirdPartyLogin } from 'hooks/useThirdParyLogin';
import {
  PhantomIconNewIcon,
  GoogleIconSvgIcon,
  SocialTwitchIconNewIcon,
  MetaMaskIconNewIcon,
  TelegramIcon,
} from 'components/ui-kit/Icons/svg/index';
import { useQuery } from 'hooks/useQuery';
import { loginWithTwitch } from 'redux-thunk/thunk/auth.thunk';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthToken } from 'helpers/cookie.helpers';
import config from 'config/app.config';
import { LocalizedNavLink } from 'components/common/LocalizedLinks';

const SocialLinks = (props) => {
  const { isBannerSection, isLoginSignup } = props;
  const dispatch = useDispatch();
  const {
    t,
    checkEthereum,
    googleLogin,
    twitchHyperlink,
    requestPhantomAccount,
    redirectToHome,
    handleTelegramAuth,
  } = useThirdPartyLogin();
  const { isPwaInstalled } = useSelector((state) => state.settings);
  const query = useQuery();
  const code = query.get('code');

  useEffect(() => {
    if (code && !getAuthToken()) {
      dispatch(loginWithTwitch({ code, redirectToHome }));
    }
  }, [code]);

  if (isBannerSection) {
    return (
      <div className="auth-social-listing-wrap">
        <div className="auth-social-heading">
          <h6>
            <span>{t('orContinueWith')}</span>
          </h6>
        </div>
        <div className="auth-social-ul d-flex align-items-center">
          {!isPwaInstalled && (
            <>
              <div className="auth-social-card flex-grow-1">
                <LocalizedNavLink
                  to="#"
                  onClick={checkEthereum}
                  className="auth-social-icon"
                >
                  <MetaMaskIconNewIcon />
                </LocalizedNavLink>
              </div>
              <div className="auth-social-card flex-grow-1">
                <LocalizedNavLink
                  to="#"
                  onClick={requestPhantomAccount}
                  className="auth-social-icon"
                >
                  <PhantomIconNewIcon />
                </LocalizedNavLink>
              </div>
            </>
          )}
          <div className="auth-social-card flex-grow-1">
            <a href={twitchHyperlink} className="auth-social-icon">
              <SocialTwitchIconNewIcon />
            </a>
          </div>
          <div className="auth-social-card flex-grow-1">
            <LocalizedNavLink
              to="#"
              onClick={googleLogin}
              className="auth-social-icon"
            >
              <GoogleIconSvgIcon />
            </LocalizedNavLink>
          </div>
          <div className="auth-social-card flex-grow-1">
            <button
              className="auth-social-icon w-100 telegram-login-button p-0"
              onClick={handleTelegramAuth}
            >
              <TelegramIcon />
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (isLoginSignup) {
    return (
      <div className="auth-social-ul auth-social-listing-wrap d-flex justify-content-center login-social-icons">
        {!isPwaInstalled && (
          <>
            <div className="auth-social-card flex-grow-1">
              <LocalizedNavLink
                to="#"
                onClick={checkEthereum}
                className="auth-social-icon w-100"
              >
                <MetaMaskIconNewIcon />
              </LocalizedNavLink>
            </div>
            <div className="auth-social-card flex-grow-1">
              <LocalizedNavLink
                to="#"
                onClick={requestPhantomAccount}
                className="auth-social-icon w-100"
              >
                <PhantomIconNewIcon />
              </LocalizedNavLink>
            </div>
          </>
        )}
        <div className="auth-social-card flex-grow-1">
          <a href={twitchHyperlink} className="auth-social-icon w-100">
            <SocialTwitchIconNewIcon />
          </a>
        </div>
        <div className="auth-social-card flex-grow-1">
          <LocalizedNavLink
            to="#"
            onClick={googleLogin}
            className="auth-social-icon w-100"
          >
            <GoogleIconSvgIcon />
          </LocalizedNavLink>
        </div>
        <div className="auth-social-card flex-grow-1">
          <button
            className="auth-social-icon w-100 telegram-login-button p-0"
            onClick={handleTelegramAuth}
          >
            <TelegramIcon />
          </button>
        </div>
      </div>
    );
  }
};

export default SocialLinks;
