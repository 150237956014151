import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enCommon from './locales/en/common.json';
import deCommon from './locales/de/common.json';
import esCommon from './locales/es/common.json';
import frCommon from './locales/fr/common.json';
import ptCommon from './locales/pt/common.json';
import { LANGUAGES } from 'constants/index';
import { getLocalLanguage } from 'helpers/localstorage.helpers';

// Get language from URL path
const getInitialLanguage = () => {
  const pathname = window.location.pathname;
  const langCode = pathname.split('/')[1];
  return LANGUAGES.some((lang) => lang.languageCode === langCode)
    ? langCode
    : getLocalLanguage() || LANGUAGES[0].languageCode;
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      common: enCommon,
    },
    de: {
      common: deCommon,
    },
    es: {
      common: esCommon,
    },
    fr: {
      common: frCommon,
    },
    pt: {
      common: ptCommon,
    },
  },
  lng: getInitialLanguage(),
  fallbackLng: LANGUAGES[0].languageCode,
  ns: 'common',
  defaultNS: 'common',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
