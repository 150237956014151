import { LANGUAGES } from 'constants/index';
import { getLocalLanguage } from './localstorage.helpers';

export const withLanguage = (path) => {
  const pathname = window.location.pathname;
  const pathParts = pathname.split('/');
  const currentLang = LANGUAGES.some(
    (lang) => lang.languageCode === pathParts[1]
  )
    ? pathParts[1]
    : getLocalLanguage() || LANGUAGES[0].languageCode;

  // Handle both prefixed and non-prefixed paths
  const validate = typeof path === 'string' ? path : path.pathname;
  const cleanPath = validate.replace(/^\/[a-z]{2}\//, '/');
  return currentLang ? `/${currentLang}${cleanPath}` : cleanPath;
};
