import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index';
import axiosInstance from 'network/apis/index';
import { microServices } from 'network/apis/microservice/index';
import { SuccessMessage } from 'network/messages/successMessages';
/**
 * Service to get user info
 */
export const getUserInfoService = () => {
  return axiosInstance(
    METHOD_TYPES.get,
    '/user/user-detail',
    {},
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.page,
    }
  );
};

/**
 * Service to update user info
//  * @param {object} data - object contains name to be updated for user
 */
export const uploadProfilePhotoService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/upload-profile-photo', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
    successMessage: SuccessMessage.profileUpdatedSuccess,
  });
};

export const updateUserInfoService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/user/update-profile', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.profileUpdatedSuccess,
  });
};

export const updateUserProfileService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/user/update-isprivate', data, {
    server: microServices.SERVICE_URL_1,
    successMessage: SuccessMessage.profileUpdatedSuccess,
  });
};

export const updatePhoneNumber = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/user/update-phone', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
  });
};

export const generateQRCodeFor2fa = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/generate-secret-code', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
  });
};

export const firstTime2faAuthenticateToken = (data) => {
  return axiosInstance(
    METHOD_TYPES.post,
    '/user/firstTime-2fa-authenticate-token',
    data,
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.submit,
      successMessage: SuccessMessage.otpVerified,
    }
  );
};

export const deactivate2faAuthenticateTokenService = (data) => {
  return axiosInstance(
    METHOD_TYPES.post,
    '/user/deactivate-two-factor-authentication',
    data,
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.submit,
      successMessage: SuccessMessage.twoFADeactivated,
    }
  );
};

export const getwithdrawTransactions = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user/getWithdrawTransaction', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
  });
};

export const getPlacedBets = (data, endpoint) => {
  return axiosInstance(METHOD_TYPES.get, endpoint, data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table,
    params: data,
  });
};

export const getAllBets = (data, endpoint) => {
  return axiosInstance(METHOD_TYPES.get, endpoint, data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data,
  });
};

export const getActiveJoiningBonusService = (data) => {
  return axiosInstance(
    METHOD_TYPES.get,
    '/bonus/get-active-joining-bonus',
    data,
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.page,
    }
  );
};

export const getActiveReferralBonusService = (data) => {
  return axiosInstance(
    METHOD_TYPES.get,
    '/bonus/get-active-referral-bonus',
    data,
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.page,
    }
  );
};

export const getTransactions = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/transaction-list', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
  });
};

export const generateWalletAddressService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/wallet/generate-address', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.walletAddress,
  });
};

export const generateReferralCodeService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/create-affiliate', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
  });
};

export const depositRequest = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/wallet/deposit-amount', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
  });
};

export const withdrawAmountRequest = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/wallet/withdraw-request', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.withdrawRequestSent,
  });
};

export const getDepositTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user/deposit-transaction', data, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.table,
  });
};

export const getWithdrawTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user/withdraw-transaction', data, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.table,
  });
};

export const getUsersCasinoTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user/casino-transaction', data, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.table,
  });
};

export const getUsersCustomGameTransactionsService = (data) => {
  return axiosInstance(
    METHOD_TYPES.get,
    '/user/custom-game-transaction',
    data,
    {
      server: microServices.SERVICE_URL_1,
      params: data,
      loader: LOADER_HANDLER_TYPES.table,
    }
  );
};

export const getUsersSportsbookTransactionsService = (data) => {
  return axiosInstance(
    METHOD_TYPES.get,
    '/user/sportsbook-transactions',
    data,
    {
      server: microServices.SERVICE_URL_1,
      params: data,
      loader: LOADER_HANDLER_TYPES.table,
    }
  );
};

export const getUsersTotalBetsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user/profit-loss', data, {
    server: microServices.SERVICE_URL_1,
    params: data,
  });
};

export const getUsersCryptoTransactionService = (params) => {
  return axiosInstance(
    METHOD_TYPES.get,
    '/transaction/get-coin-payment',
    {},
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.table,
      params,
    }
  );
};

export const setPrimaryWalletService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/set-primary-wallet', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table,
  });
};

export const getReferralUsersService = (params) => {
  return axiosInstance(
    METHOD_TYPES.get,
    '/user/affiliate-users',
    {},
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.table,
      params,
    }
  );
};

export const getCryptoTransactionLinkService = (params) => {
  return axiosInstance(
    METHOD_TYPES.get,
    '/transaction/get-transaction-info',
    {},
    {
      server: microServices.SERVICE_URL_1,
      params,
    }
  );
};

export const getUsersTipsTransactionService = (params) => {
  return axiosInstance(
    METHOD_TYPES.get,
    '/transaction/user-transactions',
    {},
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.table,
      params,
    }
  );
};

export const getUsersLoginLogsService = (params) => {
  return axiosInstance(
    METHOD_TYPES.get,
    '/user/get-login-logs',
    {},
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.table,
      params,
    }
  );
};

export const linkTwitchService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/linked-with-twitch', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.connected,
  });
};

export const linkTwitterStepOneService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user/init-twitter-token', data, {
    server: microServices.SERVICE_URL_1,
  });
};

export const linkTwitterStepTwoService = (data) => {
  return axiosInstance(
    METHOD_TYPES.post,
    '/user/linked-twitter-account',
    data,
    {
      server: microServices.SERVICE_URL_1,
      successMessage: SuccessMessage.connected,
    }
  );
};

export const affiliateCommissionService = (params) => {
  return axiosInstance(
    METHOD_TYPES.get,
    '/user/all-settlement',
    {},
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.table,
      params,
    }
  );
};

export const settlementCommissionService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/commision-settlement', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.bonusClaimedSuccessfully,
  });
};

export const referralStateService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user/affiliate-stats', data, {
    server: microServices.SERVICE_URL_1,
  });
};

export const checkSignupLimitService = () => {
  return axiosInstance(METHOD_TYPES.get, '/user/check-signup-limit', null, {
    server: microServices.SERVICE_URL_1,
  });
};

// PAYMENT IQ
export const getPaymentInitializeService = (params) => {
  return axiosInstance(
    METHOD_TYPES.get,
    '/paymentiq/initialize',
    {},
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.table,
      params,
    }
  );
};

export const getFiatTransactionsService = (params) => {
  return axiosInstance(
    METHOD_TYPES.get,
    '/user/get-transaction-fiat',
    {},
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.table,
      params,
    }
  );
};
