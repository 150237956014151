import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import LoginTab from './components/Login';
import SignupTab from './components/Signup';
import BetaFilledTab from './components/BetaFilled';
import {
  setShowLoginPopup,
  setShowSignupPopup,
} from 'redux-thunk/redux/slices/gameSetting.slice';
import { BrandLogoIcon, BetaSignup } from 'components/ui-kit/Icons/svg/index';
import CommonPopup from 'components/ui-kit/CommonPopup/index';
import './login.scss';
import { loginImg, betaSignupImg } from 'components/ui-kit/Icons/png/index';
import { LocalizedNavLink } from 'components/common/LocalizedLinks';
const Login = ({
  showSignupPopup,
  setShowVerifyEmailPopup,
  setShowBetaThankYouPopup,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [registerTabActive, setRegisterTabActive] = useState(showSignupPopup);
  const { signupLimit } = useSelector((state) => state.user);

  const betaFilledTab = registerTabActive && signupLimit;

  const modalCloseHandler = () => {
    dispatch(setShowLoginPopup(false));
    dispatch(setShowSignupPopup(false));
  };

  const getImage = () => {
    if (betaFilledTab) {
      return betaSignupImg;
    }

    return loginImg;
  };

  const renderLogo = () => {
    if (isMobile && betaFilledTab) {
      return <BetaSignup />;
    }
    return <BrandLogoIcon />;
  };

  const handleTabActive = (tab) => {
    setRegisterTabActive(tab === 'registration');
    // change query params modal is registration or login
    const url = new URL(window.location.href);
    url.searchParams.set(
      'modal',
      tab === 'registration' ? 'registration' : 'login'
    );
    window.history.pushState({}, '', url.toString());
  };

  return (
    <CommonPopup
      modalCloseHandler={modalCloseHandler}
      id="login_modal"
      modalBodyClasses="p-0"
      modalDialogClass="modal-lg modal-fullscreen-sm-down modal-dialog-scrollable"
      modalContentClass="login-modal"
      classes="auth-modal"
    >
      <div className="row">
        <div className="col-lg-6 col-12 mob-login">
          <div className="auth-form-wrap">
            <div className="mob-login-header">
              <div
                className={`auth-logo ${
                  isMobile && betaFilledTab && 'auth-logo--betaMobile'
                }`}
              >
                {renderLogo()}
              </div>
              <div className="auth-top-btn">
                <ul
                  className="nav nav-pills mb-0 mb-sm-4"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        !showSignupPopup && 'active'
                      } text-uppercase`}
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                      onClick={() => handleTabActive('login')}
                    >
                      {t('login')}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        showSignupPopup && 'active'
                      } text-uppercase`}
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                      onClick={() => handleTabActive('registration')}
                    >
                      {t('register')}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <LoginTab showSignupPopup={showSignupPopup} />
              {signupLimit ? (
                <BetaFilledTab
                  showSignupPopup={showSignupPopup}
                  setShowBetaThankYouPopup={setShowBetaThankYouPopup}
                  registerTabActive={registerTabActive}
                />
              ) : (
                <SignupTab
                  showSignupPopup={showSignupPopup}
                  setShowVerifyEmailPopup={setShowVerifyEmailPopup}
                  registerTabActive={registerTabActive}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12 p-0 login-img-wrapper d-none d-lg-block">
          <div
            className={`auth-img ${
              betaFilledTab ? 'auth-img--betaFilled' : ''
            }`}
          >
            <img src={getImage()} alt="auth" />
            <div
              className={`auth-instruction-text ${
                betaFilledTab ? 'd-none' : ''
              }`}
            >
              <p>
                {t('usersConsent')}
                <LocalizedNavLink
                  to="/site-info/terms-and-conditions"
                  onClick={() => {
                    dispatch(setShowSignupPopup(false));
                    dispatch(setShowLoginPopup(false));
                  }}
                >
                  {t('termsOfService')}
                </LocalizedNavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </CommonPopup>
  );
};

export default Login;
