import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import { adminLogout } from 'redux-thunk/thunk/auth';
// import { toggleTheme } from 'redux-thunk/redux/theme.Slice';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
import { updateHtmlLang } from 'helpers/language.helpers';
const NAV_MENU_OPTIONS = {
  logout: 'logout',
  settings: 'settings',
  profile: 'profile',
};

export const useHeaderController = ({ handleLanguageChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const { replace } = useLocalizedNavigate();

  const selectedLanguage = useSelector((state) => state.auth.language);
  // const isSideNavOpen = useSelector((state) => state.ham.openHam);
  // const currentTheme = useSelector((state) => state.theme.mode);

// const toggleThemeHandler = () => {
  //   dispatch(toggleTheme());
  // };

  const handleChangeLanguage = (languageCode) => {
    dispatch(handleLanguageChange(languageCode));
    // setAnchorElLanguage(null);
    i18n.changeLanguage(languageCode);
    // change url language
    const currentPath = window.location.pathname.substring(3);
    // replace(`/${languageCode}${currentPath}`);

    // refresh the page with new language
    window.location.href = `/${languageCode}${currentPath}`;

    // const newPath = currentPath.replace(
    //   `/${selectedLanguage}`,
    //   `/${languageCode}`
    // );
    // updateHtmlLang(languageCode);
  };

  const toggleLanguageMenu = (event, closeMenu) => {
    if (closeMenu) {
      setAnchorElLanguage(null);
    } else {
      setAnchorElLanguage(event.currentTarget);
    }
  };

  const toggleUserMenu = (event, closeMenu) => {
    if (closeMenu) {
      setAnchorElUser(null);
    } else {
      setAnchorElUser(event.currentTarget);
    }
  };

  const logoutHandler = () => {
    localStorage.removeItem('recentTabs');
    // dispatch(adminLogout({ navigate }));
  };

  return {
    anchorElUser,
    selectedLanguage,
    // isSideNavOpen,
    // currentTheme,
    toggleUserMenu,
    t,
    // navigate,
    NAV_MENU_OPTIONS,
    setAnchorElUser,
    anchorElLanguage,
    handleChangeLanguage,
    toggleLanguageMenu,
    logoutHandler,
    // toggleThemeHandler,
  };
};
