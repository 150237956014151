import React from 'react';
import { useSelector } from 'react-redux';
import { LocalizedNavLink } from 'components/common/LocalizedLinks';

const AboutUs = () => {
  const { siteInfo } = useSelector((state) => state.user);

  return (
    <ul className="m-0">
      {siteInfo
        ?.filter((el) => el.cmsSection === 'aboutUs')
        ?.map((el) => (
          <li key={el.id}>
            <LocalizedNavLink to={`/site-info/${el.slug}`}>
              {el.title}
            </LocalizedNavLink>
          </li>
        ))}
    </ul>
  );
};

export default React.memo(AboutUs);
