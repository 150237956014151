import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import config from 'config/app.config';
import NAMESPACES from 'socket-resources/namespaces/index';
import { KYC_VERIFICATION } from 'socket-resources/event-constants/kyc/index';

const useKycSocketNameSpace = () => {
  const { loginData } = useSelector((state) => state.auth); // Fetch the latest token

  const listenKycEvent = (cb) => {
    const kycInstance = io(`${config.SOCKET_URL}${NAMESPACES.KYC}`, {
      transports: ['websocket'],
      auth: {
        authorization: `Bearer ${loginData?.accessToken}`,
      },
    });

    kycInstance.on('connect', () => {
      // console.log('Connected to KYC namespace with new token')
    });

    kycInstance.on('disconnect', () => {
      // console.log('Socket disconnected from KYC namespace')
    });
    if (cb) {
      kycInstance.on(KYC_VERIFICATION, cb);
    }
    return () => {
      kycInstance?.off(KYC_VERIFICATION, cb);
      kycInstance?.disconnect();
    };
  };

  return { listenKycEvent };
};

export default useKycSocketNameSpace;
