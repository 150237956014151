import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setShowLoginPopup,
  setShowSignupPopup,
} from 'redux-thunk/redux/slices/gameSetting.slice';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';

export const usePreLogin = ({ path } = {}) => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.auth);
  const { push } = useLocalizedNavigate();

  const handleRegister = useCallback(
    ({ showLoginPopup }) => {
      if (!loginData?.accessToken) {
        if (showLoginPopup) {
          dispatch(setShowLoginPopup(true));
        } else {
          dispatch(setShowSignupPopup(true));
        }
      }
    },
    [loginData?.accessToken]
  );

  useEffect(() => {
    if (loginData?.accessToken && path) {
      push(path);
    }
  }, [loginData]);

  return { handleRegister };
};
