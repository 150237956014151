import { TOKEN } from 'constants/index';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const getAuthToken = () => {
  return cookies.get(TOKEN);
};

export const setAuthToken = (authAccessToken, sessionTime) => {
  cookies.set(TOKEN, authAccessToken, {
    path: '/',
    expires: new Date(Date.now() + sessionTime * 60 * 60 * 1000),
  });
};

export const removeAuthToken = () => {
  cookies.remove(TOKEN, { path: '/' });
};

export const signIn = ({ token, sessionTime }) => {
  setAuthToken(token, sessionTime);
};

export const signOut = () => {
  removeAuthToken();
  if (window) {
    window?.urmChat?.purge();
  }
};

/**
 * Parses payload object from jwt
 * @param {string} token
 * @returns {Object}
 */
export const getPayloadFromToken = (token) => {
  if (token) {
    return JSON.parse(atob(token.split('.')[1]));
  }
};
