import { combineReducers } from '@reduxjs/toolkit';
import user from './slices/user.slice';
import gameSetting from './slices/gameSetting.slice';
import gtm from './slices/gtm.slice';
import auth from './slices/auth.slice';
import crashGame from './slices/crashGame.slice';
import mineGame from './slices/mineGame.slice';
import plinkoGame from './slices/plinkoGame.slice';
import hiLoGame from './slices/hiLoGame.slice';
import flipCoinGame from './slices/flipCoinGame.slice';
import games from './slices/games.slice';
import settings from './slices/settings.slice';
import wallet from './slices/wallet.slice';
import demoCrashGame from './slices/demoCrashGame.slice';
import lobby from './slices/lobby.slice';
import bonus from './slices/bonus.slice';
import transactions from './slices/usersTransaction.slice';
import loader from './slices/loader.slice';
import sportsbook from './slices/sportsbook.slice';
import casino from './slices/casino.slice';
import searchPopup from './slices/searchPopup.slice';
import sumsub from './slices/sumsub.slice';
import crypto from './slices/crypto.slice';
import chat from './slices/chat.slice';
import promotion from './slices/promotion.slice';
import cryptoCoin from './slices/cryptocoin.slice';
import announcement from './slices/announcement.slice';
import limits from './slices/limits.slice';
import moonpay from './slices/moonpay.slice';

const rootReducer = combineReducers({
  user,
  settings,
  gameSetting,
  auth,
  gtm,
  crashGame,
  mineGame,
  plinkoGame,
  hiLoGame,
  flipCoinGame,
  games,
  wallet,
  demoCrashGame,
  lobby,
  bonus,
  transactions,
  loader,
  sportsbook,
  casino,
  searchPopup,
  sumsub,
  crypto,
  chat,
  promotion,
  cryptoCoin,
  announcement,
  limits,
  moonpay,
});

export default rootReducer;
