import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowDownRedIcon,
  GreenArrowUpIcon,
  BtcLiveIcon,
  SearchIconIcon,
  HeaderMenuIconIcon,
} from 'components/ui-kit/Icons/svg/index'; // GreenArrowUpIcon
import useCryptoFutureNameSpace from 'socket-resources/hooks/useCryptoFutureNameSpace';
import {
  setShowSearchPopup,
  setShowSideNav,
} from 'redux-thunk/redux/slices/settings.slice';
import { formatCryptoPrice } from 'utils/numberformatter';
import CustomTooltip from 'components/ui-kit/Tooltip/index';
import { SHOW_SIDE_NAV, TOOLTIP_VARIANT } from 'constants/index';
import { NavLink } from 'react-router-dom';
import { setItem } from 'helpers/localstorage.helpers';
import { LocalizedNavLink } from 'components/common/LocalizedLinks';

const LiveValues = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cryptoCoinsList } = useSelector((state) => state.crypto);
  const [liveBitcoinValue, setLiveBitcoinValue] = useState({
    currentPrice: 0,
    basePrice: 0,
  });
  const [btcId, setBtcId] = useState('1');
  const { listenCryptoFuturePriceEvent } = useCryptoFutureNameSpace();
  const { showSideNav } = useSelector((state) => state?.settings);

  const sidenavExpand = useCallback(() => {
    setItem(SHOW_SIDE_NAV, !showSideNav);
    dispatch(setShowSideNav(!showSideNav));
    document
      .getElementsByClassName('sidebar-wrap')[0]
      ?.classList.toggle('sidebar-close');
    document
      .getElementsByClassName('page-container')[0]
      ?.classList.toggle('sidebar-close');
    document
      .getElementsByClassName('header')[0]
      ?.classList.toggle('sidebar-close');
  }, [showSideNav]);

  const handleCryptoPriceUpdate = (data) => {
    if (data?.data?.ticker?.cryptoFuturesInstrumentId === btcId) {
      setLiveBitcoinValue({
        currentPrice: data?.data?.ticker?.price || 0,
        basePrice: data?.data?.basePrice || 0,
      });
    }
  };

  const calculatePercentageChange = useCallback((higherValue, lowerValue) => {
    const percentageChange = (
      ((higherValue - lowerValue) * 100) /
      higherValue
    )?.toFixed(2);
    return isNaN(percentageChange) ? '0.00' : percentageChange;
  }, []);

  useEffect(() => {
    if (cryptoCoinsList) {
      const [bitcoinObj] = cryptoCoinsList?.filter(
        (el) => el?.symbol === 'BTC'
      );
      setBtcId(bitcoinObj?.id);
    }
  }, [cryptoCoinsList]);

  useEffect(() => {
    const cleanerCryptoEvent = listenCryptoFuturePriceEvent(
      handleCryptoPriceUpdate
    );
    return () => {
      cleanerCryptoEvent();
    };
  }, []);

  return (
    <div className="sidebar-reverse">
      <div className="sidebar-collapse">
        {/* <div className="sidebar-balance-wrap align-balance d-flex flex-column gap-3">
          <div className="d-flex order-md-0 order-1">
            <div className="currency-icon">
              <BtcLiveIcon />
            </div>
            <div className="currency-icon-value">
              <h4 className="m-0">
                {t('bitcoin')} <span>{t('btc')}</span>
              </h4>
              <p>
                ${formatCryptoPrice(liveBitcoinValue.currentPrice)}
                {liveBitcoinValue?.currentPrice <
                liveBitcoinValue?.basePrice ? (
                  <span className="text-red d-flex justify-content-center align-items-center">
                    <ArrowDownRedIcon width="18" height="18" className="mx-1" />
                    -
                    {calculatePercentageChange(
                      liveBitcoinValue?.basePrice,
                      liveBitcoinValue?.currentPrice
                    )}
                    %
                  </span>
                ) : (
                  <span className="text-green d-flex justify-content-center align-items-center">
                    <GreenArrowUpIcon width="18" height="18" className="mx-1" />
                    +
                    {calculatePercentageChange(
                      liveBitcoinValue?.currentPrice,
                      liveBitcoinValue?.basePrice
                    )}
                    %
                  </span>
                )}
              </p>
            </div>
          </div>
        </div> */}
        <div className="sidebar-balance-wrap d-flex flex-column gap-3 ">
          <div className=" search-games w-100 position-relative">
            <input
              type="text"
              className="form-control form-control-dark"
              placeholder={t('searchGames')}
              onFocus={() => {
                dispatch(setShowSearchPopup(true));
              }}
            />
            <SearchIconIcon className="position-absolute" />
          </div>
        </div>
        <CustomTooltip
          variant={TOOLTIP_VARIANT.SIDE_BAR}
          message={showSideNav ? t('collapse') : t('expand')}
          showTooltip
        >
          <div
            className="header-toggle-icon  d-xl-block"
            onClick={sidenavExpand}
          >
            <HeaderMenuIconIcon />
          </div>
        </CustomTooltip>
      </div>
      {/* <div className="sidebar-balance-wrap d-flex flex-column gap-3 ">
        <div className=" search-games w-100 position-relative">
          <input
            type="text"
            className="form-control form-control-dark"
            placeholder={t('searchGames')}
            onFocus={() => {
              dispatch(setShowSearchPopup(true));
            }}
          />
          <SearchIconIcon className="position-absolute" />
        </div>
      </div> */}
    </div>
  );
};

export default LiveValues;
