import { useCallback } from 'react';
import { encode } from 'bs58';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useGoogleLogin } from '@react-oauth/google';
import {
  loginWithGoogle,
  loginWithPhantom,
  loginWithTelegram,
} from 'redux-thunk/thunk/auth.thunk';
import config from 'config/app.config';
import { ROUTE_PATHS } from 'constants/index';
import { openErrorToaster, openInfoToaster } from 'helpers/toaster.helpers';
import { getMetaUserRecord } from 'redux-thunk/thunk/user.thunk';
import { checkUserAddress } from 'network/services/auth.service';
import { isMobile } from 'react-device-detect';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
import { getLocalLanguage } from 'helpers/localstorage.helpers';

const useThirdPartyLogin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useLocalizedNavigate();
  const storedLang = getLocalLanguage();
  const twitchConfigs = {
    responseType: 'code',
    clientId: config.TWITCH_CLIENT_ID,
    redirectUrl: `${config.LOGIN_REDIRECT_URL}/${storedLang}`,
    scope: 'user:read:email',
  };

  const twitchHyperlink = `https://id.twitch.tv/oauth2/authorize?response_type=${twitchConfigs.responseType}&client_id=${twitchConfigs.clientId}&redirect_uri=${twitchConfigs.redirectUrl}&scope=${twitchConfigs.scope}`;

  const getPhantomProvider = () => {
    if ('phantom' in window) {
      const provider = window.phantom?.solana;
      if (provider?.isPhantom) {
        return provider;
      }
    } else if (isMobile) {
      const originalLink = window.location.href;
      const newLink = `https://phantom.app/ul/browse/${originalLink}`;
      window.open(newLink);
    }
    window.open('https://phantom.app/', '_blank');
  };

  const requestPhantomAccount = async () => {
    const provider = getPhantomProvider();
    if (provider) {
      try {
        const message = 'You are now connecting to Clutch, – Happy playing!';
        const encodedMessage = new TextEncoder().encode(message);
        await window?.ethereum?.request({
          method: 'eth_requestAccounts',
        });
        const signedMessage = await provider.signMessage(
          encodedMessage,
          'utf8'
        );
        if (signedMessage) {
          dispatch(loginWithPhantom({ code: encode(signedMessage.signature) }));
        }
      } catch (err) {
        // { code: 4001, message: 'User rejected the request.' }
      }
    }
  };
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      dispatch(loginWithGoogle(tokenResponse));
    },
    // eslint-disable-next-line
    onError: (error) => {
      openErrorToaster({ message: t('loginFailed') });
    },
    flow: 'auth-code',
  });

  const checkEthereum = () => {
    if (window.ethereum) {
      reqMetaMaskAccount();
    } else {
      window.addEventListener('ethereum#initialized', reqMetaMaskAccount, {
        once: true,
      });
      // If the event is not dispatched by the end of the timeout,
      // the user probably doesn't have MetaMask installed.
      setTimeout(reqMetaMaskAccount, 3000); // 3 seconds
    }
  };

  const handleSignMessage = async (message, nonce, accounts) => {
    try {
      const { ethereum } = window;
      const params = [message, accounts[0], nonce];
      return await ethereum.request({
        method: 'personal_sign',
        params,
      });
    } catch (error) {}
  };

  const reqMetaMaskAccount = async () => {
    // MetaMask will inject ethereum object in webpage.
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
      // ethereum is available
      try {
        const accounts = await ethereum.request({
          method: 'eth_requestAccounts',
        }); // will get all accounts
        const { nonce } = await checkUserAddress({
          ethereumAddress: accounts[0],
        });
        const message = `${config.META_MASK_SIGN_MESSAGE} \n Nonce : ${nonce}`;
        const signature = await handleSignMessage(message, nonce, accounts);
        const payload = {
          ethereumAddress: accounts[0],
          signedMessage: signature,
        };
        if (signature) {
          dispatch(getMetaUserRecord({ payload }));
        }
      } catch (err) {
        console.log('err', err);
      }
    } else if (isMobile) {
      const originalLink = window.location.href;
      const linkWithoutProtocol = originalLink.replace(/^https?:\/\//, '');
      const newLink = `https://metamask.app.link/dapp/${linkWithoutProtocol}`;
      window.open(newLink);
    } else {
      // in case ethereum is not available
      openInfoToaster({ message: t('metamaskExtensionNotAvailable') });
      setTimeout(() => {
        window.open('https://metamask.io/');
      }, 2000);
    }
  };

  const redirectToHome = useCallback(() => {
    push(ROUTE_PATHS.HOME);
  }, []);

  const redirectToProfile = useCallback(() => {
    push(ROUTE_PATHS.PROFILE);
  }, []);

  const handleTelegramAuth = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.Telegram?.Login.auth(
      { bot_id: config.TELEGRAM_BOT_ID, request_access: true },
      (data: ResponseType) => {
        if (!data) {
          console.log('ERROR: something went wrong');
        }
        dispatch(loginWithTelegram(data));
      }
    );
  };

  return {
    t,
    redirectToHome,
    checkEthereum,
    googleLogin,
    twitchHyperlink,
    requestPhantomAccount,
    redirectToProfile,
    twitchConfigs,
    handleTelegramAuth,
  };
};

export { useThirdPartyLogin };
