import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { withLanguage } from 'helpers/url.helpers';

export const useLocalizedNavigate = () => {
  const history = useHistory();

  const push = useCallback(
    (path, state) => {
      history.push(withLanguage(path), state);
    },
    [history]
  );

  const replace = useCallback(
    (path, state) => {
      history.replace(path);
    },
    [history]
  );

  // Return an object with both navigation methods
  return {
    push,
    replace,
    // Expose original history in case it's needed
    history,
  };
};
