import { METHOD_TYPES, LANGUAGES } from 'constants/index';
import { clients, microServices } from './microservice/index';
import { getLocalLanguage } from 'helpers/localstorage.helpers';

const axiosInstanceService = (method, uri, data = {}, configs = {}) => {
  const {
    showApiSuccessMessage = false,
    successMessage = null,
    server = microServices.SERVICE_URL_1,
    headers = {},
    params = {},
    responseType = 'json',
    handlerEnabled = true, // Check: Interceptors required or not
    loader, //= LOADER_HANDLER_TYPES.page
  } = configs;

  // Get language from localStorage with fallback to default language
  const currentLanguageCode = getLocalLanguage() || LANGUAGES[0].languageCode;
  const currentLanguage = LANGUAGES.find(
    (lang) => lang.languageCode === currentLanguageCode
  );

  const axiosConfig = {
    headers: {
      ...headers,
      'Accept-Language': currentLanguage?.apiCode || 'en-GB',
    },
    params,
    handlerEnabled,
    loader,
    showApiSuccessMessage,
  };

  if (responseType) {
    axiosConfig.responseType = responseType;
  }

  if (successMessage) {
    axiosConfig.successMessage = successMessage;
  }

  return clients[server][method](
    uri,
    method === METHOD_TYPES.get || method === METHOD_TYPES.delete
      ? axiosConfig
      : data,
    axiosConfig
  );
};

export default axiosInstanceService;
