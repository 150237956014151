import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ROUTE_PATHS } from 'constants/index';
import Footer from '../Footer/index';
import './mainLayout.scss';
// import { cryptoToFiat } from 'utils/helperFunctions.utils'

const MainLayout = ({ showFooter, showHeader, children }) => {
  const { showSideNav, showChat, showNotification } = useSelector(
    (state) => state?.settings
  ); // isChatCollapse
  const location = useLocation();

  const casinoRegexPattern = `${ROUTE_PATHS.CASINO_PLAYGAME}`.replace(
    ':gameName',
    '([^/]+)'
  );
  const casinoRegex = new RegExp(`${casinoRegexPattern}$`);
  const isCasinoPlayGame = casinoRegex.test(location.pathname);

  const langCode = location.pathname.substring(0, 3);
  const purePathname = location.pathname.replace(langCode, '');
  const fullWithLayout =
    !!purePathname.match(`${ROUTE_PATHS.CRYPTO}/`) ||
    isCasinoPlayGame ||
    purePathname === ROUTE_PATHS.SPORTS_BOOK;

  useEffect(() => {
    if (showHeader) {
      if (showChat || showNotification) {
        document
          .getElementById('urm-chat-container')
          .classList.add('chat-active');
      } else {
        document
          .getElementById('urm-chat-container')
          .classList.remove('chat-active');
      }
      if (!showSideNav) {
        document
          .getElementById('urm-chat-container')
          .classList.add('sidebar-close');
      } else {
        document
          .getElementById('urm-chat-container')
          .classList.remove('sidebar-close');
      }
    }
  }, [showChat, showNotification, showSideNav, showHeader]);

  return (
    <>
      <div
        id="page-container"
        className={`page-container ${!showSideNav ? 'sidebar-close' : ''}
          ${showChat || showNotification ? 'chat-parent chat-active' : ''}`}
        // style={{
        //   maxHeight: 'calc(100vh)',
        //   overflow: 'auto',
        //   position: 'relative',
        // }}
      >
        <div style={{ maxHeight: 'calc(100vh)', overflow: 'auto' }}>
          <div
            className={`${!fullWithLayout ? 'custom-spacer' : ''}
            content-wrapper
            ${purePathname === ROUTE_PATHS.SPORTS_BOOK ? 'sportsbookwrap' : ''}
            ${purePathname.includes('crypto') ? 'crypto-main-wrapper' : ''}`}
            style={{ minHeight: 'calc(100vh - 370px)' }}
          >
            {children}
          </div>

          {showFooter ? <Footer /> : <></>}
        </div>
      </div>
    </>
  );
};

export default MainLayout;
