import React, { useEffect, useState } from 'react';
import _PaymentIQCashier from 'paymentiq-cashier-bootstrapper';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import WithdrawCurrencySection from './WithdrawCurrencySection';
import DepositBuyCrypto from '../Deposit/DepositBuyCrypto';
import Loader from 'components/ui-kit/Loader/index';
import { ROUTE_PATHS } from 'constants/index';
import { setShowCashierPopup } from 'redux-thunk/redux/slices/settings.slice';
import { getPaymentInitialize } from 'redux-thunk/thunk/user.thunk';
import CashCard from 'components/ui-kit/CashCard';
import { LocalizedNavLink } from 'components/common/LocalizedLinks';
import { CASHIER_PAYMENT_TABS, LOADER_HANDLER_TYPES } from 'constants/index';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
import { WithdrawKycIcon } from 'components/ui-kit/Icons/svg/index';

const CashierWithdraw = ({
  insideTab,
  setInsideTab,
  loading,
  setLoading,
  showWithdrawOptions,
  showMoonPayWidget,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { push } = useLocalizedNavigate();
  const [selectedWithdrawWallet, setSelectedWithdrawWallet] = useState(null);

  const PaymentIQCashierInit = (merchantId, userId, sessionId, mode, rest) => {
    window._PaymentIQCashierReset();
    return new _PaymentIQCashier(
      '#cashier-withdraw',
      {
        merchantId: merchantId,
        userId: userId,
        sessionId: sessionId,
        environment: mode,
        method: 'withdrawal',
        fetchConfig: true,
        locale: 'en',
        attributes: { ...rest },
      },
      (api) => {
        setLoading(false);
        api.on({
          cashierInitLoad: () => {},
          success: (data) => {},
          error: (error) => {},
        });
      }
    );
  };

  useEffect(() => {
    if (insideTab === CASHIER_PAYMENT_TABS.WITHDRAW_PAYMENT_IQ) {
      dispatch(
        getPaymentInitialize({ method: 'withdrawal', currency: 'USD' })
      ).then((res) => {
        const { userId, sessionId, merchantId, merchantMode, ...rest } =
          res.payload;
        PaymentIQCashierInit(merchantId, userId, sessionId, merchantMode, rest);
      });
    }
  }, [insideTab]);

  return (
    <div
      className="tab-pane fade"
      id="pills-cashier-withdraw"
      role="tabpanel"
      aria-labelledby="pills-cashier-withdraw-tab"
      tabIndex="0"
    >
      {!showWithdrawOptions ? (
        <>
          <div className="deposit-step-one">
            <div className="cashier-heading">
              <h3 className="mb-0 text-uppercase">{t('withdrawalOptions')}</h3>
            </div>
            <div className="coupon-section p-3 mb-2 withdraw-kyc-section">
              <div>
                <WithdrawKycIcon />
              </div>
              <div>
                <p>
                  <span>{t('withdrawalOptions')}</span>:{' '}
                  {t('withdrawalOptionsKYC')}
                </p>
                <p>
                  <span>{t('withdrawalProcessing')}</span>:{' '}
                  {t('withdrawalProcessingText')}
                </p>
                <p>
                  <span>{t('needImmediate')}</span> <br />
                  {t('our')}
                  <a href="mailto:support@clutch.app">
                    {t('supportTeam')}
                  </a>{' '}
                  {t('needImmediateText')}
                </p>
                <div className="payment-card-right">
                  <button
                    type="button"
                    className={`btn btn-secondary`}
                    onClick={() => {
                      dispatch(setShowCashierPopup(false));
                      push(ROUTE_PATHS.ACCOUNT_VERIFICATION);
                    }}
                  >
                    {t('accountVerification')}
                  </button>
                </div>
              </div>
            </div>
            <DepositBuyCrypto
              currencyName="Crypto"
              buttonType="secondary"
              showMoonPayWidget={showMoonPayWidget}
            />
          </div>
        </>
      ) : (
        !selectedWithdrawWallet && (
          <ul
            className="nav nav-pills nav-scroll-smooth mb-3 full-width"
            role="tablist"
          >
            <li
              className={`nav-link ${
                insideTab === CASHIER_PAYMENT_TABS.WITHDRAW_OPTIONS
                  ? 'active'
                  : ''
              }`}
              role="presentation"
            >
              <LocalizedNavLink
                to="#"
                onClick={(e) => {
                  setInsideTab(CASHIER_PAYMENT_TABS.WITHDRAW_OPTIONS);
                  window._PaymentIQCashierReset();
                }}
              >
                {t('crypto')}
              </LocalizedNavLink>
            </li>
            <li
              className={`nav-link ${
                insideTab === CASHIER_PAYMENT_TABS.WITHDRAW_PAYMENT_IQ
                  ? 'active'
                  : ''
              }`}
              role="presentation"
            >
              <LocalizedNavLink
                to="#"
                onClick={(e) => {
                  setInsideTab(CASHIER_PAYMENT_TABS.WITHDRAW_PAYMENT_IQ);
                  window._PaymentIQCashierReset();
                }}
              >
                {t('fiat')}
              </LocalizedNavLink>
            </li>
          </ul>
        )
      )}
      {showWithdrawOptions &&
        (insideTab === CASHIER_PAYMENT_TABS.WITHDRAW_OPTIONS ? (
          selectedWithdrawWallet ? (
            <>
              <WithdrawCurrencySection
                selectedWithdrawWallet={selectedWithdrawWallet}
                setSelectedWithdrawWallet={setSelectedWithdrawWallet}
              />
              <DepositBuyCrypto
                currencyName="Crypto"
                buttonType="secondary"
                showMoonPayWidget={showMoonPayWidget}
              />
            </>
          ) : (
            <div className="deposit-step-one">
              <div className="deposit-card-wrap">
                <div className="row g-1">
                  {user?.wallets
                    ?.filter((wallet) => !wallet.currency.isFiat)
                    ?.filter((wallet) => wallet?.currency?.isWithdrawable)
                    ?.map((wallet) => (
                      <div key={wallet.currencyId} className="col-4 mb-1 px-1">
                        <CashCard
                          onClick={() => {
                            setSelectedWithdrawWallet(wallet);
                          }}
                          title={wallet.currency.name}
                          image={wallet.currency.image}
                          desc={wallet.currency.code}
                          enabled={!wallet?.currency?.disableWithdrawable}
                        />
                      </div>
                    ))}
                </div>
              </div>
              <DepositBuyCrypto
                currencyName="Crypto"
                buttonType="secondary"
                showMoonPayWidget={showMoonPayWidget}
              />
            </div>
          )
        ) : (
          <>
            <div id="cashier-withdraw" />
          </>
        ))}
    </div>
  );
};

export default CashierWithdraw;
