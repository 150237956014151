import React from 'react';
import { LANGUAGES } from 'constants/index';

const LanguageMenu = ({
  toggleLanguageMenu,
  anchorElLanguage,
  handleChangeLanguage,
  selectedLanguage,
}) => {
  return (
    <div className="multi-language--wrapper">
      <div className="language-wrap d-flex justify-content-between">
        <div className="dropdown language-btn">
          <button
            className="btn lottieIconButton shadow-none"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedLanguage}
          </button>
          <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton1">
            {LANGUAGES?.map((language, index) => {
              return (
                <li key={index}>
                  <a
                    className="dropdown-item"
                    // href="#"
                    onClick={() => handleChangeLanguage(language.languageCode)}
                  >
                    <div className="language-flag--wrapper">
                      <div className="language-flag">{language?.image}</div>
                      <span className="language-label">{language?.label}</span>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LanguageMenu;
