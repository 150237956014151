import { createSlice } from '@reduxjs/toolkit';
import { getGtm } from 'redux-thunk/thunk/gtm.thunk';

const initialState = {
  gtmConfig: null,
};

const {
  actions: { setGtm },
  reducer,
} = createSlice({
  name: 'gtm',
  initialState,
  reducers: {
    setGtm: (state, action) => {
      return {
        ...state,
        gtmConfig: action.payload?.gtmConfig,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGtm.fulfilled, (state, action) => {
      return {
        ...state,
        gtmConfig: action?.payload?.gtmConfig,
      };
    });
  },
});

export { setGtm };
export default reducer;
