import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import useSlideGesture from './useSlideGesture';

const ARROW_WIDTH_WITHOUT_LEFT_PADDING = 36;

const NavScrollWrapper = ({ children }) => {
  const navRef = useRef(null);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const { onMouseUp, onMouseDown, onMouseLeave, onMouseMove } = useSlideGesture(
    { containerRef: navRef }
  );

  useEffect(() => {
    const navElement = navRef.current;
    if (!navElement) return;

    const handleButtonVisibility = () => {
      const fullWidth = navElement.scrollWidth;
      const visibleWidth = navElement.offsetWidth;
      const scrollPosition = navElement.scrollLeft;

      setShowRightArrow(
        Math.ceil(visibleWidth + scrollPosition) < fullWidth - 1
      );
      setShowLeftArrow(scrollPosition > 0);
    };

    const navResize = new ResizeObserver(handleButtonVisibility);
    navElement.addEventListener('scroll', handleButtonVisibility);
    navResize.observe(navElement);

    return () => {
      navResize.disconnect();
      navElement.removeEventListener('scroll', handleButtonVisibility);
    };
  }, []);

  const handleScrollRight = () => {
    const visibleWidth = navRef.current.offsetWidth;
    for (const child of navRef.current.children) {
      if (
        navRef.current.scrollLeft + visibleWidth >
        child.offsetLeft + child.offsetWidth
      ) {
        continue;
      }
      navRef.current.scrollLeft = child.offsetLeft;
      break;
    }
  };

  const handleScrollLeft = () => {
    for (let i = navRef.current.children.length - 1; i >= 0; i--) {
      const child = navRef.current.children[i];
      if (navRef.current.scrollLeft < child.offsetLeft) {
        continue;
      }
      navRef.current.scrollLeft = Math.max(
        0,
        child.offsetLeft - navRef.current.offsetWidth
      );
      break;
    }
  };

  const handleOnItemSelect = (event) => {
    const isElementVisibleInRightPart =
      navRef.current.scrollLeft +
        navRef.current.offsetWidth -
        ARROW_WIDTH_WITHOUT_LEFT_PADDING <
      event.currentTarget.offsetLeft + event.currentTarget.offsetWidth;
    const isElementVisibleInLeftPart =
      navRef.current.scrollLeft > event.currentTarget.offsetLeft &&
      navRef.current.scrollLeft <
        event.currentTarget.offsetLeft + event.currentTarget.offsetWidth;

    if (isElementVisibleInRightPart || isElementVisibleInLeftPart) {
      navRef.current.scrollLeft = event.currentTarget.offsetLeft;
    }
  };

  const renderChildrenWithProps = () => {
    return React.Children.map(
      children,
      (child) =>
        React.isValidElement(child) &&
        React.cloneElement(child, {
          onClick: (event) => {
            child.props.onClick?.();
            handleOnItemSelect(event);
          },
        })
    );
  };

  useEffect(() => {
    if (!navRef.current) return;

    const selectedItem = Array.from(navRef.current.children).find((child) =>
      child.classList.contains('active')
    );

    if (!selectedItem) return;

    const isActiveElementNotFullyVisible =
      navRef.current.scrollLeft + navRef.current.offsetWidth - 36 >
      selectedItem.offsetLeft + selectedItem.offsetWidth;

    if (isActiveElementNotFullyVisible) return;

    navRef.current.scrollLeft = selectedItem.offsetLeft;
  }, []);

  return (
    <div className="nav-scroll-wrapper">
      {showLeftArrow && (
        <div className="nav-scroll-arrow-wrapper left">
          <button
            className="nav-scroll-arrow left"
            onClick={handleScrollLeft}
            type="button"
          />
        </div>
      )}
      <ul
        className="nav nav-pills nav-scroll-smooth"
        id="pills-tab"
        role="tablist"
        ref={navRef}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      >
        {renderChildrenWithProps()}
      </ul>
      {showRightArrow && (
        <div className="nav-scroll-arrow-wrapper right">
          <button
            className="nav-scroll-arrow right"
            onClick={handleScrollRight}
            type="button"
          />
        </div>
      )}
    </div>
  );
};

export default NavScrollWrapper;
