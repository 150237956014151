import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from 'constants/index';
import { useDispatch } from 'react-redux';
import { handleLanguageChange } from 'redux-thunk/redux/slices/auth.slice';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
import { updateHtmlLang } from 'helpers/language.helpers';
import {
  getLocalLanguage,
  setLocalLanguage,
} from 'helpers/localstorage.helpers';

const LanguageRoute = ({ children }) => {
  const { lang } = useParams();
  const { i18n } = useTranslation();
  const { replace } = useLocalizedNavigate();
  const dispatch = useDispatch();

  // Get stored language
  const storedLang = getLocalLanguage();

  // Validate current URL language code
  const isValidLang = LANGUAGES.some((l) => l.languageCode === lang);
  const isValidLangStored = LANGUAGES.some(
    (l) => l.languageCode === storedLang
  );

  if (!isValidLangStored) {
    // Redirect to default language if invalid
    setLocalLanguage(LANGUAGES[0].languageCode);
    replace(`/${LANGUAGES[0].languageCode}`);
    // return children;
    window.location.href = `/${LANGUAGES[0].languageCode}`;
  }

  if (!isValidLang) {
    // Redirect to default language if invalid
    const defaultLang = storedLang || LANGUAGES[0].languageCode;
    const currentPath = window.location.pathname;

    // Remove leading and trailing slashes and split path
    const pathSegments = currentPath.replace(/^\/|\/$/g, '').split('/');
    const firstSegment = pathSegments[0];

    // Check if first segment is potentially a language code (2 letters)
    const isPotentialLangCode = firstSegment && firstSegment.length === 2;
    const isValidLangCode = LANGUAGES.some(
      (l) => l.languageCode === firstSegment
    );

    // if (isPotentialLangCode && isValidLangCode) {
    if (isPotentialLangCode) {
      // If it's a valid language code, redirect to root with new language
      // replace(`/${defaultLang}`);

      const urlParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlParams.entries());

      // If it's not a valid language code or just 2 letters, treat as regular path
      const pathWithParams = new URL(
        `/${storedLang}${currentPath.replace(`${firstSegment}/`, '')}`,
        window.location.origin
      );

      pathWithParams.search = window.location.search;
      window.location.href = pathWithParams.pathname + pathWithParams.search;

      return children;
    }

    // if (!isPotentialLangCode && !isValidLangCode) {
    //   console.log('redirect to default lang');
    //   replace(`/${defaultLang}/${window.location.pathname}`);
    //   return;
    // }

    //keep url params
    const urlParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams.entries());

    // If it's not a valid language code or just 2 letters, treat as regular path
    const pathWithParams = new URL(`/${storedLang}`, window.location.origin);

    pathWithParams.search = window.location.search;
    pathWithParams.pathname = `/${storedLang}${window.location.pathname}`;

    window.location.href = pathWithParams.pathname + pathWithParams.search;
    return children;
  }

  // Check if URL language matches stored language
  if (storedLang && lang !== storedLang) {
    const currentPath = window.location.pathname;

    // Remove leading and trailing slashes and split path
    const pathSegments = currentPath.replace(/^\/|\/$/g, '').split('/');
    const firstSegment = pathSegments[0];

    // Check if first segment is potentially a language code (2 letters)
    const isPotentialLangCode = firstSegment && firstSegment.length === 2;
    const isValidLangCode = LANGUAGES.some(
      (l) => l.languageCode === firstSegment
    );

    // if (isPotentialLangCode && isValidLangCode) {
    if (isPotentialLangCode && pathSegments.length === 1) {
      // If it's a valid language code, redirect to root with new language
      replace(`/${storedLang}`);
      return children;
    }

    //keep url params && add params to the path
    const pathWithParams = new URL(
      `/${storedLang}${currentPath.replace(`${firstSegment}/`, '')}`,
      window.location.origin
    );

    pathWithParams.search = window.location.search;
    // If it's not a valid language code or just 2 letters, treat as regular path
    // replace that two letters with the stored language
    replace(pathWithParams.pathname + pathWithParams.search);
    return children;
  }

  // Update language if different
  if (i18n.language !== lang) {
    i18n.changeLanguage(lang);
    dispatch(handleLanguageChange(lang));
    // Update HTML lang attribute
    updateHtmlLang(lang);
  }

  return children;
};

export default LanguageRoute;
