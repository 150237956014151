import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorMessage from 'components/layout/ErrorMessage';
import { showLoginOtpHandle } from 'redux-thunk/redux/slices/user.slice';
import {
  // forgetPassword,
  userLogin,
} from 'redux-thunk/thunk/auth.thunk';
import {
  setShowLoginPopup,
  setShowSignupPopup,
} from 'redux-thunk/redux/slices/gameSetting.slice';
import Loader from 'components/ui-kit/Loader/index';
import { LOADER_HANDLER_TYPES, ROUTE_PATHS } from 'constants/index.js';
import SocialLinks from '../SocialLinks/index';
import { setShowForgotPasswordPopup } from 'redux-thunk/redux/slices/settings.slice';
import { EyeCloseIcon, EyeIcon } from 'components/ui-kit/Icons/svg/index';
import { LocalizedNavLink } from 'components/common/LocalizedLinks';
const Login = ({ showSignupPopup }) => {
  const { t } = useTranslation();
  // const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(
  //   (state) => state.loader
  // );

  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [token, setToken] = useState('');

  const schema = yup.object().shape({
    userName: yup.string().required(`${t('signinErrorsUserNameRequired')}`),
    password: yup
      .string()
      .required(`${t('signinErrorsPasswordRequired')}`)
      .min(8, `${t('signinErrorsPasswordMinLength')}`)
      .max(16, `${t('signinErrorsPasswordMaxLength')}`),
    userToken: yup.string(),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const onLoginSuccess = (res) => {
    if (window && window?.urmChat?.mergeProfile) {
      window?.urmChat
        ?.mergeProfile([window.urm_data?.appData?.profileId, res.user.id])
        .then(() => {
          setLoading(false);

          if (
            location.pathname?.includes('/verify') ||
            location.pathname.includes('/reset-password')
          ) {
            window.location.href = ROUTE_PATHS.HOME;
          } else {
            // reload the page and remove login query params
            window.location.href = location.pathname;
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      if (
        location.pathname?.includes('/verify') ||
        location.pathname.includes('/reset-password')
      ) {
        window.location.href = ROUTE_PATHS.HOME;
      } else {
        window.location.href = location.pathname;
      }
    }

    // if (location.pathname.match('/casino/play-game') || location.pathname.match('/reset-password')) {
    //   history.replace(ROUTE_PATHS.CASINO)
    // } else if (location.pathname?.match('/verify')) {
    //   history.replace(ROUTE_PATHS.HOME)
    // } else {
    //   history.replace(location.pathname)
    // }
    // dispatch(setShowLoginPopup(false))
    // dispatch(setShowSignupPopup(false))
  };

  const OnLoginError = (error) => {
    setLoading(false);
    if (error[0].description === 'otp required') {
      dispatch(setShowLoginPopup(false));
      dispatch(showLoginOtpHandle(true));
      dispatch(setShowSignupPopup(false));
    }
  };

  const handleLoginSubmit = async (loginDetails) => {
    setLoading(true);
    const payload = {
      userNameOrEmail: loginDetails.userName,
      password: loginDetails.password.trim(),
      onLoginSuccess,
      OnLoginError,
    };
    if (token?.trim()?.length > 0) {
      payload.userToken = token;
    }
    dispatch(userLogin(payload));
  };

  const handleTokenChange = (event) => {
    setToken(event.target.value);
  };

  // const handleForgetPassword = async () => {
  //   if (username?.length > 0) {
  //     dispatch(forgetPassword({ userNameOrEmail: username }))
  //   } else {
  //     openErrorToaster({ message: t('signinResetPasswordUsernameErr') })(
  //   }
  // }

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };
  return (
    <div
      className={`tab-pane fade ${!showSignupPopup && 'show active'}`}
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div className="register-wrap pb-4">
        <form onSubmit={handleSubmit(handleLoginSubmit)}>
          <div className="mb-3 form-group ">
            <label htmlFor="userEmail" className="form-label text-uppercase">
              {t('signinUsername')}
            </label>{' '}
            <span className="color-red ">*</span>
            <input
              type="text"
              placeholder={t('emailPlaceholder')}
              className={`form-control form-control-dark shadow-none ${
                errors?.userName ? 'error-input' : ''
              }`}
              id="userName"
              name="userName"
              {...register('userName')}
              autoComplete="off"
              maxLength={50}
            />
            {errors && errors.userName && (
              <div className="error-container">
                <ErrorMessage
                  className="color-red text-danger error-msg login-input  ms-2 d-flex mb-0"
                  message={errors.userName.message}
                />
              </div>
            )}
          </div>
          <div className="mb-3 form-group ">
            <label htmlFor="userEmail" className="form-label text-uppercase">
              {t('profilePassword')}
            </label>{' '}
            <span className="color-red ">*</span>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="********"
              className={`form-control form-control-dark shadow-none ${
                errors?.password ? 'error-input' : ''
              }`}
              id="password"
              name="password"
              autoComplete="off"
              {...register('password')}
              maxLength={50}
              minLength={8}
              aria-label="Username"
              aria-describedby="button-addon1"
            />
            <a
              onClick={() => setShowPassword((prevState) => !prevState)}
              className="show-password"
            >
              {!showPassword ? <EyeIcon /> : <EyeCloseIcon />}
            </a>
            <div className="error-container mt-1 d-flex justify-content-between">
              <div>
                {errors && errors.password && (
                  <ErrorMessage
                    className="color-red text-danger error-msg login-input  ms-2 d-flex mb-0"
                    message={errors.password.message}
                  />
                )}
              </div>
              <a
                href="#"
                className="forgot-password"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setShowForgotPasswordPopup(true));
                }}
              >
                {t('signinForgetPassword')}
              </a>
            </div>
          </div>
          <div className="mb-3 form-group  twofa-wrap">
            <div
              className="d-flex align-items-center"
              onClick={!isDropdownOpen ? toggleDropdown : () => {}}
            >
              <label
                htmlFor="2FACode"
                className="form-label text-uppercase"
                style={{ marginRight: '10px' }}
              >
                {t('twoFACode')}
              </label>
              {!isDropdownOpen && (
                <div className="dropdown-icon mb-2">
                  <i
                    className="fa fa-caret-down"
                    aria-hidden="true"
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              )}
            </div>
            {isDropdownOpen && (
              <>
                <input
                  type="text"
                  placeholder={t('code')}
                  className={`form-control form-control-dark shadow-none ${
                    errors?.userToken ? 'error-input' : ''
                  }`}
                  id="userToken"
                  name="userToken"
                  {...register('userToken')}
                  value={token}
                  onChange={handleTokenChange}
                />
                <p className="pt-2 mb-3">
                  Required if you enabled two-factor authentication
                </p>
              </>
            )}
            {errors && errors.userToken && (
              <ErrorMessage
                className="color-red text-danger error-msg login-input  ms-2 d-flex mb-0"
                message={errors.userToken.message}
              />
            )}
          </div>
          <div className="auth-btn-wrap">
            <button
              type="submit"
              className="btn btn-secondary w-100"
              disabled={loading}
            >
              {loading ? (
                <Loader variant={LOADER_HANDLER_TYPES.submit} />
              ) : (
                <span>{t('login')}</span>
              )}
            </button>
          </div>
          <div className="auth-socail-wrap">
            <div className="auth-social-heading">
              <h6>
                <span>{t('orContinueWith')}</span>
              </h6>
            </div>
          </div>
          <SocialLinks isLoginSignup />
          <div className="auth-mob-instructions d-block d-lg-none pb-5 pb-md-0">
            <p>
              {t('usersConsent')}
              <LocalizedNavLink
                to="/site-info/terms-and-conditions"
                onClick={() => {
                  dispatch(setShowSignupPopup(false));
                  dispatch(setShowLoginPopup(false));
                }}
              >
                {t('termsOfService')}
              </LocalizedNavLink>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
