import React from 'react';
import { Redirect } from 'react-router-dom';
import PublicRoute from './HOC/PublicRoutes';
import PrivateRoute from './HOC/PrivateRoutes';
import { useSelector } from 'react-redux';
import { ROUTE_PATHS } from 'constants/index';
import { withLanguage } from 'helpers/url.helpers';
import { getAuthToken } from 'helpers/cookie.helpers';

const RouteValidator = ({ route, match, ...props }) => {
  try {
    const { loginData } = useSelector((state) => state.auth);
    const isAuthenticated = !!getAuthToken();

    // Remove language prefix from path for comparison
    const path = match.path.replace('/:lang?', '');

    // Only redirect if we're not already on the target path
    const currentPath = window.location.pathname;
    const targetHomePath = withLanguage(ROUTE_PATHS.HOME);
    const targetLoginPath = withLanguage(ROUTE_PATHS.LOGIN);

    if (isAuthenticated && route.isPublicOnly) {
      if (currentPath !== targetHomePath) {
        return <Redirect to={withLanguage(ROUTE_PATHS.HOME)} />;
      }
      return null;
    }

    if (!isAuthenticated && route.isPrivate) {
      if (currentPath !== targetLoginPath) {
        return <Redirect to={withLanguage(ROUTE_PATHS.LOGIN)} />;
      }
      return null;
    }

    if (route.isPrivate) {
      return (
        <PrivateRoute
          {...props}
          {...route}
          path={path}
          exact
          isAuthenticated={isAuthenticated}
        />
      );
    }

    return <PublicRoute {...props} {...route} path={path} exact />;
  } catch (error) {
    console.error('Error in RouteValidator:', error);
    return null;
  }
};

export default RouteValidator;
