import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  setInviteFriendsStatus,
  setShowCashierPopup,
} from 'redux-thunk/redux/slices/settings.slice';
import CashierDeposit from './components/Deposit/index';
import CashierWithdraw from './components/Withdraw/index';
import CashierCoupons from './components/Coupons/index';
import CashierReferrals from './components/Referrals/index';
import CommonPopup from 'components/ui-kit/CommonPopup/index';
import NavScrollWrapper from 'components/ui-kit/NavScrollWrapper';
import { CASHIER_PAYMENT_TABS } from 'constants/index';

const CashierPopup = ({ showMoonPayWidget, showWithdrawOptions }) => {
  const dispatch = useDispatch();
  const { inviteFriendsStatus } = useSelector((state) => state.settings);
  const { t } = useTranslation();

  const [insideTab, setInsideTab] = useState(
    CASHIER_PAYMENT_TABS.DEPOSIT_OPTIONS
  );
  const [loading, setLoading] = useState(true);

  const modalCloseHandler = () => {
    dispatch(setShowCashierPopup(false));
  };

  const cashierTabs = useMemo(
    () => [
      {
        id: 'pills-cashier-deposit',
        label: t('deposit'),
      },
      {
        id: 'pills-cashier-withdraw',
        label: t('withdraw'),
      },
      // {
      //   id: 'pills-cashier-coupons',
      //   label: t('coupons'),
      // },
      {
        id: 'pills-cashier-referrals',
        label: t('referrals'),
      },
    ],
    []
  );

  useEffect(() => {
    return () => {
      if (inviteFriendsStatus) {
        dispatch(setInviteFriendsStatus(false));
      }
    };
  }, []);

  const paymentIqOnWithdraw =
    insideTab === CASHIER_PAYMENT_TABS.WITHDRAW_PAYMENT_IQ &&
    showWithdrawOptions;

  return (
    <CommonPopup
      classes={`cashier-modal ${
        (insideTab === 'payment-iq' || insideTab === 'withdraw-payment-iq') &&
        'cashier-small'
      }`}
      modalCloseHandler={
        (insideTab === CASHIER_PAYMENT_TABS.PAYMENT_IQ ||
          paymentIqOnWithdraw) &&
        loading
          ? () => {}
          : modalCloseHandler
      }
      id="cashierModal"
    >
      <div className="custom-tabs-nav mb-0 pb-1">
        <NavScrollWrapper>
          {cashierTabs?.map((tab, idx) => (
            <li key={tab.id} className="nav-item" role="presentation">
              <button
                className={`nav-link text-uppercase ${
                  idx === 0 && !inviteFriendsStatus
                    ? 'active'
                    : tab.id === 'pills-cashier-referrals' &&
                      inviteFriendsStatus
                    ? 'active'
                    : ''
                }`}
                id={`${tab.id}-tab`}
                data-bs-toggle="pill"
                data-bs-target={`#${tab.id}`}
                type="button"
                role="tab"
                aria-controls={`${tab.id}`}
                aria-selected={idx === 0 ? 'true' : 'false'}
                onClick={() => {
                  setLoading(true);
                  if (tab.id === 'pills-cashier-deposit') {
                    setInsideTab(CASHIER_PAYMENT_TABS.DEPOSIT_OPTIONS);
                  } else {
                    setInsideTab(CASHIER_PAYMENT_TABS.WITHDRAW_OPTIONS);
                  }
                  window._PaymentIQCashierReset();
                }}
              >
                {tab.label}
              </button>
            </li>
          ))}
        </NavScrollWrapper>
      </div>
      <div
        className={`tab-content ${loading ? '' : 'incHeight'}`}
        id="pills-tabContent"
      >
        <CashierDeposit
          showMoonPayWidget={showMoonPayWidget}
          insideTab={insideTab}
          setInsideTab={setInsideTab}
          loading={loading}
          setLoading={setLoading}
        />
        <CashierWithdraw
          insideTab={insideTab}
          setInsideTab={setInsideTab}
          loading={loading}
          setLoading={setLoading}
          showMoonPayWidget={showMoonPayWidget}
          showWithdrawOptions={showWithdrawOptions}
        />
        <CashierCoupons />
        <CashierReferrals />
      </div>
    </CommonPopup>
  );
};

export default CashierPopup;
