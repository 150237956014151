import React, { useEffect, useState } from 'react';
import Tooltip from 'rc-tooltip';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import { uniqBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/ui-kit/Loader/index';
import {
  GifIcon,
  EmojiSmileIcon,
  InfoNewIcon,
} from 'components/ui-kit/Icons/svg/index';
import Mentions from '../Mentions/index';
import { earthIcon } from 'components/ui-kit/Icons/png/index';
import { setSelectedRoomId } from 'redux-thunk/redux/slices/chat.slice';
import { sendChatMessage } from 'redux-thunk/thunk/chat.thunk';
import { LocalizedNavLink } from 'components/common/LocalizedLinks';
import Gif from './Gif';
import {
  MAX_MESSAGE_CHARACTERS_LIMIT,
  LOADER_HANDLER_TYPES,
} from 'constants/index';

const ChatFooter = ({ bottomRef }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const {
    chatRules,
    chat,
    languageRooms,
    selectedRoomId,
    selectedChatGroupId,
    loading,
  } = useSelector((state) => state.chat);
  const { loginData } = useSelector((state) => state.auth);
  const [message, setMessage] = useState('');
  const [mentionsData, setMentionsData] = useState([]);
  const [gif, setGif] = useState(false);
  const [gifSearch, setGifSearch] = useState('');

  const handleScroll = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'instant' });
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (
      loginData?.accessToken &&
      message.length <= MAX_MESSAGE_CHARACTERS_LIMIT &&
      message.length > 0
    ) {
      dispatch(
        sendChatMessage({
          message,
          userName: user?.userName,
          languageId: selectedRoomId,
          groupId: selectedChatGroupId,
        })
      );
      setMessage('');
      handleScroll();
    }
  };

  const onClickEmoji = (emojiData, event) => {
    if (loginData?.accessToken) {
      setMessage((prevMsg) =>
        (prevMsg + emojiData.emoji).length > MAX_MESSAGE_CHARACTERS_LIMIT
          ? prevMsg
          : prevMsg + emojiData.emoji
      );
    }
  };

  useEffect(() => {
    if (chat?.count > 0) {
      const mentionsName = chat?.rows
        ?.filter(
          (item) =>
            item?.userId !== Number(user?.id) &&
            item?.languageId === selectedRoomId
        )
        ?.map((userChat) => ({
          id: userChat?.userId,
          display: userChat?.user?.userName,
        }));
      // .filter((item) => item.id !== user?.userName)
      setMentionsData(uniqBy(mentionsName, 'id'));
      // setItem(CHAT, chat)
    }
    if (
      chat?.rows?.length === 20 ||
      chat?.rows?.[chat?.rows?.length - 1]?.userId?.toString() ===
        user?.id?.toString()
    ) {
      // to scroll chat at initial render(limit=20)
      handleScroll();
    }
  }, [chat]);

  return (
    <div className="chat-bottom-section">
      {gif && (
        <div>
          <input
            type="text"
            name="gifSearch"
            className="form-control gif-input"
            placeholder="Search Gifs..."
            value={gifSearch}
            onChange={(e) => setGifSearch(e.target.value)}
          />
        </div>
      )}
      <form onSubmit={handleSendMessage}>
        <div className="chat-typing">
          {/* <textarea class="form-control" id="exampleFormControlTextarea1" ></textarea> */}
          <Mentions
            setMessage={setMessage}
            message={message}
            mentionsData={mentionsData}
          />
          <div className="chat-input-cta">
            {/* <button
              onClick={() => {
                setGif(!gif)
              }}
            >
              <GifIcon />
            </button> */}
            {gif && <Gif setGif={setGif} gifSearch={gifSearch} />}
            <Tooltip
              placement="topRight"
              trigger={['click']}
              overlayStyle={{ fontSize: '0.7rem' }}
              overlay={
                <div>
                  <EmojiPicker
                    onEmojiClick={onClickEmoji}
                    autoFocusSearch={false}
                    emojiStyle={EmojiStyle.NATIVE}
                    theme="dark"
                    lazyLoadEmojis
                  />
                </div>
              }
            >
              <EmojiSmileIcon />
            </Tooltip>
          </div>
        </div>
        <div className="chat-details-wrap d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2">
            {loading ? (
              <Loader variant={LOADER_HANDLER_TYPES.submit} />
            ) : (
              <>
                {languageRooms?.rows?.length <= 1 ? (
                  <div className="language-btn single-language">
                    <span className="btn btn-primary single">
                      <img
                        src={
                          languageRooms?.rows?.find(
                            (ele) => ele.id === selectedRoomId
                          )?.languageLogo || earthIcon
                        }
                        alt={
                          languageRooms?.rows?.find(
                            (ele) => ele.id === selectedRoomId
                          )?.language
                        }
                      />
                      {
                        languageRooms?.rows?.find(
                          (ele) => ele.id === selectedRoomId
                        )?.language
                      }
                    </span>
                  </div>
                ) : (
                  <div className="dropdown language-btn dropup">
                    <button
                      className="btn btn-primary dropdown-toggle shadow-none "
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-display="static"
                    >
                      <img
                        src={
                          languageRooms?.rows?.find(
                            (ele) => ele.id === selectedRoomId
                          )?.languageLogo || earthIcon
                        }
                        alt="Earth"
                      />
                      {
                        languageRooms?.rows?.find(
                          (ele) => ele.id === selectedRoomId
                        )?.language
                      }
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {languageRooms?.rows?.length > 0 &&
                        languageRooms?.rows?.map((lang) => (
                          <li key={lang?.id}>
                            <LocalizedNavLink
                              activeClassName="dropdown-item"
                              to="#"
                              onClick={() =>
                                dispatch(setSelectedRoomId(lang?.id))
                              }
                            >
                              <img
                                src={lang?.languageLogo || earthIcon}
                                alt="Earth"
                              />
                              {lang?.language}
                            </LocalizedNavLink>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </>
            )}

            <Tooltip
              placement="top"
              trigger={['click']}
              overlayStyle={{
                minWidth: '320px',
                maxWidth: '320px',
                opacity: '1',
              }}
              overlayClassName="chat-rules-overlay"
              overlay={
                <span>
                  <ul className="tooltip-listing">
                    {chatRules?.map((rule, idx) => (
                      <li key={idx}>{rule}</li>
                    ))}
                  </ul>
                </span>
              }
            >
              <LocalizedNavLink
                to={location.pathname}
                className="chat-rules text-uppercase"
              >
                <InfoNewIcon />
              </LocalizedNavLink>
            </Tooltip>
          </div>
          <div className="d-flex align-items-center">
            <div
              className="progress-wrap d-flex"
              style={{ '--c': message.length >= 200 ? '' : 'var(--blue-300)' }}
            >
              <div
                className="pie animate no-round"
                style={{
                  '--p': message.length / 2,
                  '--c': message.length >= 200 ? '' : 'var(--blue-300)',
                }}
              />
            </div>

            <small className="text-muted me-1 text-limit">
              {message.length < MAX_MESSAGE_CHARACTERS_LIMIT
                ? MAX_MESSAGE_CHARACTERS_LIMIT - message.length
                : 0}
            </small>
            <button
              className="btn btn-secondary ms-2"
              type="submit"
              disabled={
                !loginData?.accessToken ||
                message.length > MAX_MESSAGE_CHARACTERS_LIMIT ||
                message.length <= 0
              }
            >
              {t('send')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChatFooter;
