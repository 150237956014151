import React from 'react';
import MainLayout from 'components/layout/MainLayout/index';

const PublicRoute = ({
  component: Component,
  isAuthenticated,
  child,
  showFooter = false,
  showHeader,
}) => {
  return (
    <MainLayout showFooter={showFooter} showHeader={showHeader}>
      <Component child={child} />
    </MainLayout>
  );
};

export default PublicRoute;
