import { METHOD_TYPES } from 'constants/index';
import { microServices } from 'network/apis/microservice/index';
import axiosInstance from 'network/apis/index';
import { SuccessMessage } from 'network/messages/successMessages';

export const getGtmService = () => {
  return axiosInstance(
    METHOD_TYPES.get,
    'google-tag-manager',
    {},
    {
      server: microServices.SERVICE_URL_1,
    }
  );
};
