import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'

export const getGameIframeUrlService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/ear-casino/game-launch', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const getDemoGameIframeUrlService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/ear-casino/game-launch-demo', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const getLiveTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/system/wins', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.liveWins
  })
}

export const getLiveTransactionsBetService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/system/bets', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.liveBets
  })
}

export const getHighRollerService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/casino/highrollers', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
