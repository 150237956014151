import React from 'react';
import { useTranslation } from 'react-i18next';
import CommonPopup from 'components/ui-kit/CommonPopup/index';
import styles from './beta-thankyou.module.scss';
import { BrandLogoIcon } from '../../../ui-kit/Icons/svg';
import { betaThankYouImg } from '../../../ui-kit/Icons/png';
import { XIcon, TelegramWhiteIcon } from 'components/ui-kit/Icons/svg/index';
const BetaSignupSuccessPopup = ({ handleClose }) => {
  const { t } = useTranslation();

  return (
    <CommonPopup
      modalCloseHandler={() => {
        handleClose(false);
      }}
      classes="verify-email"
    >
      <div className="d-flex justify-content-center flex-column text-center">
        <div className="popup-bg d-flex align-items-center justify-content-center">
          <img src={betaThankYouImg} alt="" className={styles.bannerImage} />
        </div>
        <div className="popup-content pt-0">
          <h2 className="text-uppercase mb-0">{t('betaThankyouTitle')}</h2>
          <p className="mb-4">{t('betaThankyouText')}</p>
          <div className="btn-wrap">
            <button
              type="button"
              className="btn btn-flex btn-primary btn-outline"
              onClick={() =>
                window.open('https://x.com/PlayClutchApp', '_blank')
              }
            >
              <span className="btn-icon btn-icon-left">
                <XIcon />
              </span>
              {/* {t('share')} */}
              {t('follow')}
            </button>

            <button
              type="button"
              className="btn btn-flex btn-secondary"
              // onClick={() => handleClose(false)}
              onClick={() =>
                window.open('https://t.me/PlayClutchApp', '_blank')
              }
            >
              <span className="btn-icon btn-icon-left">
                <TelegramWhiteIcon />
              </span>
              {t('join')}
            </button>
          </div>
          <p className={styles.footer}>
            <BrandLogoIcon />
          </p>
        </div>
      </div>
    </CommonPopup>
  );
};

export default BetaSignupSuccessPopup;
