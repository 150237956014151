import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { generateReferralCode } from 'redux-thunk/thunk/user.thunk';
import { handleCopyCode } from 'utils/helperFunctions.utils';

export const useReferral = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [referralCode, setReferralCode] = useState('');
  const [err, setErr] = useState('');

  const handleChangeInput = (e) => {
    if (e?.target?.value?.trim()?.length < 1) {
      setErr(t('minOneCharacterRequired'));
    } else {
      setErr('');
    }
    setReferralCode(e?.target?.value);
  };

  const handleClick = () => {
    if (user?.affiliate?.url) {
      handleCopyCode(user?.affiliate?.url, t('referralLinkCopied'));
    } else {
      if (referralCode?.trim()?.length > 0) {
        dispatch(generateReferralCode({ affiliateCode: referralCode }));
      } else {
        setErr(t('minOneCharacterRequired'));
      }
    }
  };

  return {
    err,
    handleChangeInput,
    handleClick,
    isReferralLinkAvailable: !!user?.affiliate?.url,
    referralLink: user?.affiliate?.url,
    referralCode: user?.affiliate?.code,
  };
};
