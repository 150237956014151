import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startCase, uniqBy } from 'lodash';
import moment from 'moment';
import { getLiveTransactionsBet } from 'redux-thunk/thunk/casino.thunk';
import { useTranslation } from 'react-i18next';
import useLiveWinsSocketNameSpace from 'socket-resources/hooks/useLiveWinsSocketNameSpace';
import {
  LIVE_DATA,
  LIVE_WINS_RECORDS_LIMIT,
  LIVE_WINS_TYPES,
  TRANSACTION_TABS,
} from 'constants/index';
import { setLiveData } from 'redux-thunk/redux/slices/casino.slice';
// import { setItem } from 'helpers/localstorage.helpers'
import { toLocalTime } from 'utils/helperFunctions.utils';

const AllBets = (props) => {
  const { tab } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { liveData, liveTransactionsBetData } = useSelector(
    (state) => state.casino
  );

  const { currencies } = useSelector((state) => state.user);
  const [liveTableData, setLiveTableData] = useState();
  const [mergedData, setMergedData] = useState([]);
  const { listenLiveWinsUpdateEvent } = useLiveWinsSocketNameSpace();

  const mainCurrencySymbol = currencies?.find(
    (currency) => currency?.primary
  )?.symbol;

  useEffect(() => {
    dispatch(getLiveTransactionsBet());
  }, []);

  const handleLiveWinsData = (e) => {
    dispatch(setLiveData({ data: e?.data }));
  };

  const calculateDifferenceInSeconds = (dateString) => {
    return (
      parseInt(
        moment.duration(moment().diff(moment(dateString))).asSeconds()
      ) || 0
    );
  };

  // useEffect(() => {
  //   if (liveData?.length > 0) {
  //     setItem(LIVE_DATA, liveData)
  //   }
  // }, [liveData])

  useEffect(() => {
    const cleanUpListener = listenLiveWinsUpdateEvent(handleLiveWinsData);
    return () => {
      cleanUpListener();
    };
  }, []);

  useEffect(() => {
    if (liveData?.length > 1) {
      const initialValue = {};
      const uniqueData = uniqBy(liveData, 'data.id');
      const liveBets = uniqueData.reduce((accumulator, currentValue) => {
        if (accumulator[currentValue.data.roundId]) {
          if (currentValue.data.type === 'casino-bet') {
            accumulator[currentValue.data.roundId]['casino-bet'] +=
              currentValue.data.amount;
          } else {
            accumulator[currentValue.data.roundId]['casino-win'] +=
              currentValue.data.amount;
          }
        } else {
          accumulator[currentValue.data.roundId] = {
            amount: currentValue.data.amount,
            'casino-bet':
              currentValue.data.type === 'casino-bet'
                ? currentValue.data.amount
                : 0,
            'casino-win':
              currentValue.data.type === 'casino-win'
                ? currentValue.data.amount
                : 0,
            casinoGameId: currentValue.data.casinoGameId,
            gameName: currentValue.data.gameName,
            id: currentValue.data.id,
            image: currentValue.data.image,
            user: currentValue.data.user,
            roundId: currentValue.data.roundId,
            time: currentValue.data.time,
          };
        }
        return accumulator;
      }, initialValue);
      setLiveTableData(liveBets);
    }
  }, [liveData]);

  useEffect(() => {
    if (
      Object.keys(liveTableData || {})?.length &&
      liveTransactionsBetData?.rows
    ) {
      const newArray = Object.values(liveTableData).map((obj) => ({
        'casino-bet': obj?.['casino-bet'] || 0,
        'casino-win': obj?.['casino-win'] || 0,
        casinoGameId: obj?.casinoGameId,
        gameName: obj?.gameName,
        id: obj?.id,
        image: obj?.image,
        user: obj?.user,
        roundId: obj?.roundId,
        time: obj?.time,
        amount: obj?.amount,
      }));

      const updatedData = [...newArray, ...liveTransactionsBetData.rows];
      return setMergedData(updatedData);
    } else {
      if (liveTransactionsBetData?.rows.length) {
        return setMergedData(liveTransactionsBetData.rows);
      }
    }
  }, [liveTableData, liveTransactionsBetData?.rows]);

  return (
    <div
      className={`tab-pane fade show ${
        tab === TRANSACTION_TABS.ALL_BETS ? 'active' : ''
      }`}
      id="lobby"
      role="tabpanel"
    >
      <div className="table-responsive">
        <table className={`table theme-table theme-table-bets animated-table`}>
          <thead className="table-head">
            <tr>
              <th scope="col" className="text-start">
                {t('gameText')}
              </th>
              <th scope="col" className="text-start">
                {t('player')}
              </th>
              <th scope="col" className="text-start">
                {t('time')}
              </th>
              <th scope="col" className="text-start">
                {t('roundId')}
              </th>
              <th scope="col" className="text-start">
                {t('wager')}
              </th>
              <th scope="col" className="text-start">
                {t('payoutText')}
              </th>
            </tr>
          </thead>
          <tbody>
            {mergedData && mergedData.length > 0 ? (
              mergedData
                ?.filter((data) => (data.type === 'casino-bet') !== 0)
                ?.slice(0, LIVE_WINS_RECORDS_LIMIT)
                ?.map((data) => (
                  <tr key={`${data?.roundId}`} className="table-animation">
                    <td>
                      <div className="user-details d-flex align-items-center">
                        <div className="user-image me-2">
                          <img src={data?.image} height={36} width={36} />
                        </div>
                        {startCase(data?.gameName)}
                      </div>
                    </td>
                    <td>{startCase(data?.user?.userName)}</td>
                    {/* <td>{calculateDifferenceInSeconds(data?.time)}s</td> */}
                    <td>
                      {toLocalTime(data?.createdAt, 'h:mm A')}{' '}
                      {toLocalTime(data?.createdAt, 'MM/DD/YYYY')}
                    </td>
                    <td>{data?.roundId}</td>
                    <td>{`${mainCurrencySymbol} ${
                      data[LIVE_WINS_TYPES.CASINO_BET]
                    }`}</td>
                    <td>
                      <div
                        className={`${
                          data[LIVE_WINS_TYPES.CASINO_WIN] > 0
                            ? 'text-success'
                            : ''
                        }`}
                      >
                        {`${mainCurrencySymbol} ${
                          data[LIVE_WINS_TYPES.CASINO_WIN]
                        }`}
                      </div>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  {t('dataNotFound')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(AllBets);
