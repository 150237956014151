import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getGtm } from 'redux-thunk/thunk/gtm.thunk';
import { useDispatch } from 'react-redux';
const GoogleTagManager = () => {
  const { gtmConfig } = useSelector((state) => state.gtm);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGtm());
  }, []);

  useEffect(() => {
    if (gtmConfig?.gtmId) {
      // Add the script tag dynamically
      const script = document.createElement('script');
      script.async = true;
      script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmConfig?.gtmId}');
    `;
      document.head.appendChild(script);
    }
  }, [gtmConfig?.gtmId]);

  return gtmConfig?.gtmId ? (
    // Add noscript content for the body
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${gtmConfig?.gtmId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
        title="GTM"
      ></iframe>
    </noscript>
  ) : null;
};

export default GoogleTagManager;
