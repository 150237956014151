import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import { LeftArrowIcon } from 'components/ui-kit/Icons/svg/index';
import { goldICon } from 'components/ui-kit/Icons/png/index';
import { LocalizedNavLink } from 'components/common/LocalizedLinks';
import {
  blockInvalidChar,
  cryptoToFiat,
  handleCopyCode,
} from 'utils/helperFunctions.utils';
import { generateWalletAddress } from 'redux-thunk/thunk/user.thunk';
import {
  CRYPTO_COINS,
  LOADER_HANDLER_TYPES,
  ROUTE_PATHS,
} from 'constants/index';
import Loader from 'components/ui-kit/Loader/index';
import { setShowCashierPopup } from 'redux-thunk/redux/slices/settings.slice';
import DepositBuyCrypto from './DepositBuyCrypto';

const DepositCurrencySection = ({
  selectedWallet,
  setSelectedWallet,
  showMoonPayWidget,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { [LOADER_HANDLER_TYPES.walletAddress]: loading } = useSelector(
    (state) => state.loader
  );
  const [fiatAmount, setFiatAmount] = useState('');
  const [cryptoAmount, setCryptoAmount] = useState('');
  const PRIMARY_FIAT = 'USD';

  const generateAddress = (wallet) => {
    if (!wallet?.walletAddress) {
      const params = {
        currencyCode: wallet.currency.code,
        currencyId: Number(wallet.currency.id),
      };
      dispatch(generateWalletAddress({ params }));
    }
  };

  const handleChangeCrypto = (e) => {
    setCryptoAmount(e.target.value);
    cryptoToFiat(
      CRYPTO_COINS[selectedWallet?.currency?.code],
      PRIMARY_FIAT,
      e.target.value
    )
      .then((res) => {
        setFiatAmount(!res ? 0 : res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChangeFiat = (e) => {
    setFiatAmount(e.target.value);
    cryptoToFiat(
      PRIMARY_FIAT,
      CRYPTO_COINS[selectedWallet?.currency?.code],
      e.target.value
    )
      .then((res) => {
        setCryptoAmount(!res ? 0 : res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (selectedWallet) {
      generateAddress(selectedWallet);
    }
  }, [selectedWallet]);

  if (loading) {
    return <Loader variant={LOADER_HANDLER_TYPES.content} />;
  }

  return (
    <div className="deposit-step-two" style={{ paddingTop: '1.5rem' }}>
      <div className="cashier-heading">
        <h3 className="mb-0 text-uppercase">
          <LocalizedNavLink
            to="#"
            className="left-arrow"
            onClick={() => {
              setSelectedWallet(null);
            }}
          >
            <LeftArrowIcon />
          </LocalizedNavLink>
          <div className="currency-icon">
            <img src={selectedWallet?.currency?.image} alt="Bitcoin" />
          </div>
          {t('deposit')} {selectedWallet?.currency?.name}
        </h3>
        <LocalizedNavLink
          to={`${ROUTE_PATHS.DEPOSIT}?currency=${selectedWallet?.currency?.name}`}
          onClick={() => {
            dispatch(setShowCashierPopup(false));
          }}
        >
          {t('viewTransactions')}
        </LocalizedNavLink>
      </div>
      <div className="modal-inner-card mb-2">
        <div className="d-md-flex mb-md-2">
          <p>
            {t('sendAmountInCashier', {
              currency: selectedWallet?.currency?.name,
            })}
          </p>
          {selectedWallet?.walletAddress && (
            <div className="qr-code">
              <QRCode value={selectedWallet?.walletAddress} />
            </div>
          )}
        </div>
        <div className="change-username copy-btn-container">
          <div className="form-group">
            <label htmlFor="newUser" className="form-label">
              {t('yourPersonalDepositAddress', {
                currency: selectedWallet?.currency?.name,
              })}
            </label>
            <div className="position-relative">
              <input
                type="text"
                className="form-control form-copy shadow-none"
                id="newUser"
                value={selectedWallet?.walletAddress}
                placeholder={t('yourPersonalDepositAddress', {
                  currency: selectedWallet?.currency?.name,
                })}
                readOnly
              />
              <button
                type="button"
                className="btn btn-secondary"
                onClick={(e) =>
                  handleCopyCode(
                    selectedWallet?.walletAddress,
                    t('depositCopied')
                  )
                }
              >
                <span className="d-none d-md-block">{t('copyAddress')}</span>
                <span className="d-md-none">{t('copy')}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-inner-card mb-2">
        <div className="d-md-flex align-items-center justify-content-center currency-converter">
          <div className="form-group w-100">
            <input
              type="number"
              className="form-control shadow-none"
              id="fiat"
              placeholder={t('topHeaderAmount')}
              value={fiatAmount}
              onChange={(e) => {
                handleChangeFiat(e);
              }}
              onKeyDown={blockInvalidChar}
            />
            <img src={goldICon} alt="Coin" className="me-2" />
          </div>
          <span>=</span>
          <div className="form-group w-100">
            <input
              type="number"
              className="form-control shadow-none"
              id="crypto"
              placeholder={t('topHeaderAmount')}
              value={cryptoAmount}
              onChange={(e) => {
                handleChangeCrypto(e);
              }}
              onKeyDown={blockInvalidChar}
            />
            <img
              src={selectedWallet?.currency?.image}
              alt="Coin"
              className="me-2"
            />
          </div>
        </div>
        <p className="text-center p-0 mt-3">
          {t('theValueOfCurrencyMayChange', {
            currency: selectedWallet?.currency?.code,
          })}
        </p>
      </div>
      <DepositBuyCrypto
        currencyName="Crypto"
        buttonType="secondary"
        showMoonPayWidget={showMoonPayWidget}
        // currencyName={selectedWallet?.currency?.name}
        // buttonType="primary"
      />
    </div>
  );
};

export default DepositCurrencySection;
