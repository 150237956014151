import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  setShowSignupPopup,
  setShowLoginPopup,
} from 'redux-thunk/redux/slices/gameSetting.slice';
import { setCryptoCoin } from 'redux-thunk/redux/slices/cryptocoin.slice';
import { ROLLER_COASTER, ROUTE_PATHS } from 'constants/index';
import SocialLinks from 'pages/Login/components/SocialLinks/index';
import { ClutchGameOn } from 'components/ui-kit/Icons/png/index';
import './index.scss';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
import { LocalizedLink } from 'components/common/LocalizedLinks';
const BannerSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useLocalizedNavigate();
  const location = useLocation();
  const { loginData } = useSelector((state) => state.auth);
  const { cryptoCoinsList, cryptoCoins24hData } = useSelector(
    (state) => state.crypto
  );

  const registerHandler = () => {
    loginData?.accessToken
      ? push(ROUTE_PATHS.CASINO)
      : dispatch(setShowSignupPopup(true));
  };

  const langCode = location.pathname.substring(0, 3);
  const purePathname = location.pathname.replace(langCode, '');
  return (
    <div className="bannerSection">
      <div>
        <img
          src={ClutchGameOn}
          className="bannerSection-image img-fluid d-block mx-auto mb-1 game-on-logo-img"
          alt="clutch-game-on"
        />
      </div>
      <div
        className={`bannerSection-content d-flex flex-wrap mb-4 ${
          langCode === '/fr' && 'padding-0'
        }`}
      >
        <LocalizedLink
          to={ROUTE_PATHS.CASINO}
          className="text-uppercase flex-grow-1"
        >
          {t('play')}
        </LocalizedLink>
        <LocalizedLink
          to={`#`}
          // to={`${ROUTE_PATHS.CRYPTO}/${cryptoCoinsList[0]?.id}`}
          className="text-uppercase flex-grow-1"
          onClick={(e) => {
            if (!loginData?.accessToken) {
              dispatch(setShowLoginPopup(true));
            }
            dispatch(
              setCryptoCoin({
                name: cryptoCoinsList[0]?.name,
                symbol: cryptoCoinsList[0]?.symbol,
                id: cryptoCoinsList[0]?.id,
              })
            );
          }}
        >
          {t('bet')}
        </LocalizedLink>
        <LocalizedLink
          to={`#`}
          // to={`${ROUTE_PATHS.CRYPTO}/${ROLLER_COASTER.id}`}
          className="text-uppercase flex-grow-1"
          onClick={(e) => {
            if (!loginData?.accessToken) {
              dispatch(setShowLoginPopup(true));
            }
            dispatch(
              setCryptoCoin({
                name: 'Roller Coaster',
                symbol: 'rollercoaster',
                id: ROLLER_COASTER.id,
              })
            );
          }}
        >
          {t('trade')}
        </LocalizedLink>
        <LocalizedLink
          to={`#`}
          // to={ROUTE_PATHS.SPORTS_BOOK}
          className="text-uppercase flex-grow-1"
        >
          {t('win')}
        </LocalizedLink>
      </div>
      <div className="banner-btn-wrap">
        <button
          type="button"
          className={`btn btn-secondary w-100 ${
            purePathname === ROUTE_PATHS.CASINO ? 'd-none' : ''
          }`}
          onClick={registerHandler}
        >
          {loginData?.accessToken ? t('playNow') : t('registerNow')}
        </button>
        {!loginData?.accessToken && <SocialLinks isBannerSection />}
      </div>
    </div>
  );
};

export default React.memo(BannerSection);
