import { createSlice } from '@reduxjs/toolkit';
import { WAGERING_STATUS } from 'constants/index';
import {
  getAccumulatedBonus,
  getAllBonus,
  getAllRanksDetailsOfUser,
  getReferralBonus,
  getSplittedBonus,
  getTotalRewards,
  getUserBonus,
} from 'redux-thunk/thunk/bonus.thunk';

const initialState = {
  allBonus: null,
  userBonus: null,
  referralBonus: null,
  totalRewards: null,
  totalRewardsLoading: false,
  allRanksDetails: null,
  splittedBonus: { groupedBonuses: {} },
  accumulatedBonus: null,
};

const {
  actions: {
    setUserBonus,
    setReferralBonus,
    setSplittedBonus,
    setAccumulatedBonus,
    setTotalRewards,
    setAccumulatedBonusWagering,
  },
  reducer,
} = createSlice({
  name: 'bonus',
  initialState,
  reducers: {
    setReferralBonus: (state, action) => ({
      ...state,
      referralBonus: action.payload,
    }),
    setUserBonus: (state, action) => ({
      ...state,
      userBonus: { userbonusDetails: action.payload },
    }),
    setSplittedBonus: (state, action) => ({
      ...state,
      splittedBonus: { groupedBonuses: action.payload },
    }),
    setAccumulatedBonus: (state, action) => ({
      ...state,
      accumulatedBonus: action.payload,
    }),
    setTotalRewards: (state, action) => ({
      ...state,
      totalRewards: {
        ...state.totalRewards,
        ...action.payload,
      },
    }),
    setAccumulatedBonusWagering: (state, action) => {
      const { id, bonusAmount, wageringStatus, wageredAmount, amountToWager } =
        action.payload;
      return {
        ...state,
        accumulatedBonus: state?.accumulatedBonus?.map((bonus) =>
          bonus?.userBonusId === id
            ? {
                ...bonus,
                bonusAmount:
                  wageringStatus === WAGERING_STATUS.COMPLETE
                    ? bonusAmount
                    : bonus?.bonusAmount,
                wageringStatus: wageringStatus,
                wageredAmount: wageredAmount,
                amountToWager: amountToWager,
              }
            : bonus
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBonus.fulfilled, (state, action) => {
        return {
          ...state,
          allBonus: action.payload,
        };
      })
      .addCase(getUserBonus.fulfilled, (state, action) => {
        return {
          ...state,
          userBonus: action.payload,
        };
      })
      .addCase(getReferralBonus.fulfilled, (state, action) => {
        return {
          ...state,
          referralBonus: action.payload,
        };
      })
      .addCase(getTotalRewards.pending, (state, action) => {
        return {
          ...state,
          totalRewards: action.payload,
          totalRewardsLoading: true,
        };
      })
      .addCase(getTotalRewards.rejected, (state, action) => {
        return {
          ...state,
          totalRewards: action.payload,
          totalRewardsLoading: false,
        };
      })
      .addCase(getTotalRewards.fulfilled, (state, action) => {
        return {
          ...state,
          totalRewards: action.payload,
          totalRewardsLoading: false,
        };
      })
      .addCase(getAllRanksDetailsOfUser.fulfilled, (state, action) => {
        return {
          ...state,
          allRanksDetails: action.payload,
        };
      })
      .addCase(getSplittedBonus.fulfilled, (state, action) => {
        return {
          ...state,
          splittedBonus: action.payload,
        };
      })
      .addCase(getAccumulatedBonus.fulfilled, (state, action) => {
        return {
          ...state,
          accumulatedBonus: action.payload,
        };
      });
  },
});

export default reducer;
export {
  setUserBonus,
  setReferralBonus,
  setSplittedBonus,
  setAccumulatedBonus,
  setTotalRewards,
  setAccumulatedBonusWagering,
};
