import { Link, NavLink } from 'react-router-dom';
import { withLanguageUrl } from './withLanguageUrl';
import { useLocalizedActive } from 'hooks/useLocalizedActive';
import { withLanguage } from 'helpers/url.helpers';

const LocalizedNavLinkComponent = ({
  to,
  activeClassName,
  className,
  ...props
}) => {
  const isActive = useLocalizedActive(to);
  const finalClassName = `${className || ''} ${
    isActive && activeClassName ? activeClassName : ''
  }`.trim();

  return <Link {...props} to={withLanguage(to)} className={finalClassName} />;
};

export const LocalizedLink = withLanguageUrl(Link);
export const LocalizedNavLink = LocalizedNavLinkComponent;
