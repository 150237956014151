import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { startCase } from 'lodash';
import { useReferral } from 'hooks/useReferral';
import './referralSection.scss';

const CashierReferrals = () => {
  const { t } = useTranslation();
  const { inviteFriendsStatus } = useSelector((state) => state.settings);
  const { referralState } = useSelector((state) => state.user);
  const {
    err,
    handleChangeInput,
    handleClick,
    isReferralLinkAvailable,
    referralLink,
    referralCode,
  } = useReferral();

  return (
    <div
      className={`tab-pane fade ${inviteFriendsStatus ? 'show active' : ''}`}
      id="pills-cashier-referrals"
      role="tabpanel"
      aria-labelledby="pills-cashier-referrals-tab"
      tabIndex="0"
    >
      <div className="coupon-section referral-section">
        <img src="/assets/images/homePage/new/referral.png" alt="Gold" />
        <h4 className="text-uppercase">{t('referralCode')}</h4>
        <div className="modal-inner-card">
          <div className="change-username">
            <div className="form-group text-start">
              <label htmlFor="newUser" className="form-label">
                {startCase(t('referralCode'))}
              </label>
              <div className="position-relative">
                <input
                  type="text"
                  className={`form-control form-copy shadow-none ${
                    err ? 'error-input' : ''
                  }`}
                  id="newUser"
                  value={referralCode}
                  placeholder={
                    isReferralLinkAvailable
                      ? referralLink
                      : t('setReferralLinkName')
                  }
                  readOnly={isReferralLinkAvailable}
                  onChange={handleChangeInput}
                />
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClick}
                >
                  <span>
                    {isReferralLinkAvailable ? (
                      <>
                        <span className="d-none d-md-block">
                          {t('copyLink')}
                        </span>
                        <span className="d-md-none">{t('copy')}</span>
                      </>
                    ) : (
                      t('create')
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal-inner-card"
          style={{
            borderTop: 'solid 1px var(--gray-85)',
            borderRadius: 0,
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
          }}
        >
          <div className="change-username">
            <div className="form-group text-start">
              <div className="position-relative flex">
                <div className="refferal-item">
                  <p>
                    $
                    {Number(referralState?.adminAffiliatesProfitCommision) === 0
                      ? Number(referralState?.adminAffiliatesProfitCommision)
                      : Number(
                          referralState?.adminAffiliatesProfitCommision
                        ).toFixed(2)}
                  </p>
                  <span>{t('comission')}</span>
                </div>
                <div className="refferal-item">
                  <p>
                    $
                    {Number(referralState?.totalWagering) === 0
                      ? Number(referralState?.totalWagering)
                      : Number(referralState?.totalWagering).toFixed(2)}
                  </p>
                  <span>{t('wagered')}</span>
                </div>
                <div className="refferal-item">
                  <p>{referralState?.totalDepositAmount}</p>
                  <span>{t('totalDeposit')}</span>
                </div>
                <div className="refferal-item">
                  <p>-</p>
                  <span>{t('lastDeposit')}</span>
                </div>
                <div className="refferal-item">
                  <p>{referralState?.profitCommisionPercentage || '-'}</p>
                  <span>{t('comissionTier')}</span>
                </div>
                <div className="refferal-item">
                  <p>{referralState?.totalAffiliatedCount || '-'}</p>
                  <span>{t('totalRefferedUsers')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CashierReferrals);
