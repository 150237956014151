import React, { useEffect, useState } from 'react';
import Tooltip from 'rc-tooltip';
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { Controller } from 'react-hook-form';
import { TOOLTIP_VARIANT } from 'constants/index';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ErrorMessage from 'components/layout/ErrorMessage/index';
import './phoneInput.style.scss';

const PhoneInputField = ({
  label,
  control,
  name,
  rules,
  defaultValue,
  theme = 'light',
  required = false,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name.toLowerCase()}
      control={control}
      defaultValue={defaultValue || ''}
      rules={rules}
      render={({ field, fieldState }) => (
        <div className={`${theme}`}>
          {label && (
            <label htmlFor={name} className="form-label text-uppercase">
              {label}
              {required && <span className="color-red"> *</span>}
            </label>
          )}
          <PhoneInput
            {...field}
            international
            defaultCountry="AU"
            placeholder=""
            enableSearch
            className={fieldState.invalid ? 'error' : ''}
          />
          {fieldState.error && (
            <div className="error-container">
              <ErrorMessage
                className="color-red text-danger error-msg login-input ms-2 d-flex"
                message={fieldState.error.message}
              />
            </div>
          )}
        </div>
      )}
    />
  );
};

export default PhoneInputField;
