import { createSlice } from '@reduxjs/toolkit';
import {
  cashOutHiLo,
  getUnfinishedBet,
  getMyBetsHiLo,
  openCardHiLo,
  placeBetHiLo,
} from 'redux-thunk/thunk/hiLoGame.thunk';
import { BET_RESULT, DEFAULT_PAGE_CALLS } from 'constants/index';
import { randomCardGenerate } from 'pages/HiLoGame/game-helper/index';

const initialState = {
  initialCard: randomCardGenerate(),
  betStates: null,
  betData: null,
  betLock: false,
  cardLock: false,
  betHistory: {
    count: 0,
    rows: [],
  },
  previousSelectedMultiplier: null,
  stateLoaded: false,
  myInitialCard: null,
  btnLoading: false,
};

const {
  actions: {
    setInitialCard,
    setMyInitialCard,
    setStateLoaded,
    setBetLock,
    setCardLock,
    setBtnLoader,
  },
  reducer,
} = createSlice({
  name: 'hiloGame',
  initialState,
  reducers: {
    setInitialCard: (state, action) => {
      return {
        ...state,
        initialCard: action.payload,
      };
    },
    setBetLock: (state, action) => {
      return {
        ...state,
        betLock: action.payload,
      };
    },
    setCardLock: (state, action) => {
      return {
        ...state,
        cardLock: action.payload,
      };
    },
    setMyInitialCard: (state, action) => {
      return {
        ...state,
        myInitialCard: action.payload,
      };
    },
    setStateLoaded: (state, action) => {
      return {
        ...state,
        stateLoaded: action.payload,
      };
    },
    setBtnLoader: (state, action) => {
      return {
        ...state,
        btnLoading: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(placeBetHiLo.fulfilled, (state, action) => {
        return {
          ...state,
          betStates: null,
          betLock: true,
          betData: action.payload,
          myInitialCard: action.payload.initialCard,
          cardLock: false,
          btnLoading: false,
          betId: action.payload.id,
        };
      })
      .addCase(placeBetHiLo.rejected, (state, action) => {
        return {
          ...state,
          betLock: false,
          cardLock: false,
          btnLoading: false,
        };
      })
      .addCase(openCardHiLo.fulfilled, (state, action) => {
        let betStates = null;
        let initialCard = null;
        let betLock = state.betLock;
        const myInitialCard = action?.payload?.initialCard;
        if (action.payload?.betStates && action.payload.betStates.length > 0) {
          if (action.payload.result === BET_RESULT.LOST) {
            betStates = action.payload.betStates
              ?.map((bet, idx) =>
                idx === action.payload.betStates.length - 1
                  ? { ...bet, result: BET_RESULT.LOST }
                  : bet
              )
              .reverse();
          } else {
            betStates = action.payload.betStates.reverse();
          }
          initialCard = betStates?.[0]?.openedCard;
        }
        let betHistory = state.betHistory;
        let betData = state.betData;
        if (
          action.payload?.result &&
          action.payload.result === BET_RESULT.LOST
        ) {
          betLock = false;
          betHistory = state.betHistory.rows
            ? {
                count: state.betHistory.count + 1,
                rows: [action.payload, ...state.betHistory.rows],
              }
            : { count: 1, rows: [action.payload] };

          betData = { ...state.betData, result: BET_RESULT.LOST };
          if (betHistory.rows?.length > DEFAULT_PAGE_CALLS) {
            betHistory?.rows?.pop();
          }
        }
        return {
          ...state,
          betLock,
          initialCard,
          betStates,
          myInitialCard,
          betHistory,
          betData,
          cardLock: false,
        };
      })
      .addCase(getUnfinishedBet.pending, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(getUnfinishedBet.fulfilled, (state, action) => {
        if (action.payload?.hasUnfinishedBet) {
          const { unfinishedBet } = action.payload;
          let betStates = null;
          let betId = null;
          let initialCard = null;
          const betLock = true;
          const betAmount = unfinishedBet?.betAmount;
          const myInitialCard = unfinishedBet?.initialCard;
          const betData = unfinishedBet;
          if (unfinishedBet?.betStates && unfinishedBet.betStates.length > 0) {
            betStates = unfinishedBet.betStates.reverse();
            initialCard = betStates?.[0]?.openedCard;
          } else if (
            unfinishedBet?.betStates &&
            unfinishedBet.betStates.length === 0
          ) {
            initialCard = unfinishedBet?.initialCard;
          }
          if (unfinishedBet?.id) {
            betId = unfinishedBet?.id;
          }

          return {
            ...state,
            betLock,
            initialCard,
            betStates,
            betAmount,
            myInitialCard,
            stateLoaded: true,
            betData,
            betId,
          };
        }
        return {
          ...state,
          stateLoaded: true,
        };
      })
      .addCase(getUnfinishedBet.rejected, (state, action) => {
        return {
          ...state,
          stateLoaded: true,
        };
      })
      .addCase(cashOutHiLo.fulfilled, (state, action) => {
        const betHistory = state.betHistory.rows
          ? {
              count: state.betHistory.count + 1,
              rows: [action.payload, ...state.betHistory.rows],
            }
          : { count: 1, rows: [action.payload] };
        return {
          ...state,
          betLock: false,
          betData: action.payload,
          betAmount: '',
          betHistory,
          cardLock: false,
          btnLoading: false,
        };
      })
      .addCase(cashOutHiLo.rejected, (state, action) => {
        return {
          ...state,
          btnLoading: false,
        };
      })
      .addCase(getMyBetsHiLo.fulfilled, (state, action) => {
        return {
          ...state,
          betHistory: action.payload,
        };
      });
  },
});

export {
  setInitialCard,
  setMyInitialCard,
  setStateLoaded,
  setBetLock,
  setCardLock,
  setBtnLoader,
};

export default reducer;
