import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';
import config from 'config/app.config';
import NAMESPACES from 'socket-resources/namespaces/index';
import { RANKING } from 'socket-resources/event-constants/ranking/index';
import { getLocalLanguage } from 'helpers/localstorage.helpers';
const useRankingSocketNameSpace = () => {
  const { loginData } = useSelector((state) => state.auth); // Fetch the latest token

  const listenRankingUpdateEvent = (cb) => {
    const rankingInstance = io(`${config.SOCKET_URL}${NAMESPACES.RANKING}`, {
      transports: ['websocket'],
      auth: {
        authorization: `Bearer ${loginData?.accessToken}`,
      },
    });

    rankingInstance.on('connect', () => {
      // console.log('Connected to RANKING namespace with new token')
    });

    rankingInstance.on('disconnect', () => {
      // console.log('Socket disconnected from RANKING namespace')
    });
    if (cb) {
      rankingInstance.on(RANKING, (data) => {
        const lang = getLocalLanguage();
        let dataTranslatedTOlang = { data: {} };
        dataTranslatedTOlang.data = {
          ...data?.data,
          rank: data?.data?.rank[lang],
          description: data?.data?.description[lang],
        };
        cb(dataTranslatedTOlang);
      });
    }
    return () => {
      rankingInstance?.off(RANKING, cb);
      rankingInstance?.disconnect();
    };
  };

  return { listenRankingUpdateEvent };
};

export default useRankingSocketNameSpace;
