import { createSlice } from '@reduxjs/toolkit';
import {
  getUsersTotalLimits,
  setUsersWagerLimits,
  setUsersLossLimits,
  setUsersDepositLimits,
  setUsersBreakLimits,
  setUsersSessionLimits,
  setUsersResetLimits,
} from 'redux-thunk/thunk/limits.thunk';

const initialState = {
  usersTotalLimit: null,
  loading: false,
};

const { reducer } = createSlice({
  name: 'limits',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsersTotalLimits.fulfilled, (state, action) => {
        return {
          ...state,
          usersTotalLimit: action?.payload,
          loading: false,
        };
      })
      .addCase(getUsersTotalLimits.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(getUsersTotalLimits.pending, (state, action) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(setUsersWagerLimits.fulfilled, (state, action) => {
        return {
          ...state,
          usersWagerLimit: action?.payload,
        };
      })
      .addCase(setUsersLossLimits.fulfilled, (state, action) => {
        return {
          ...state,
          usersLossLimit: action?.payload,
        };
      })
      .addCase(setUsersDepositLimits.fulfilled, (state, action) => {
        return {
          ...state,
          usersDepositLimit: action?.payload,
        };
      })
      .addCase(setUsersBreakLimits.fulfilled, (state, action) => {
        return {
          ...state,
          usersBreakLimit: action?.payload,
        };
      })
      .addCase(setUsersSessionLimits.fulfilled, (state, action) => {
        return {
          ...state,
          usersSessionLimit: action?.payload,
        };
      })
      .addCase(setUsersResetLimits.fulfilled, (state, action) => {
        return {
          ...state,
          usersResetLimit: action?.payload,
        };
      });
  },
});

export default reducer;
