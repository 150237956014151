import React from 'react';
import './index.scss';

const CasinoCard = (props) => {
  const { image, title, desc, onClick, enabled } = props;

  return (
    <div
      className={`cashCard ${enabled ? '' : 'disabled'}`}
      onClick={enabled ? onClick : null}
    >
      <div className="cashCard-icon">
        <img width={32} height={32} src={image} alt="Coin" />
      </div>
      <h4>{title}</h4>
      <p>{desc}</p>
    </div>
  );
};

export default React.memo(CasinoCard);
