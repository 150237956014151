import MainLayout from 'components/layout/MainLayout/index';
import React from 'react';
import { Redirect } from 'react-router-dom';

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  child,
  showFooter,
  showHeader,
}) => {
  return isAuthenticated ? (
    <MainLayout showFooter={showFooter} showHeader={showHeader}>
      <Component child={child} />
    </MainLayout>
  ) : (
    <MainLayout showFooter={showFooter} showHeader={showHeader}>
      <Redirect to={{ pathname: '/' }} />
    </MainLayout>
  );
};

export default PrivateRoute;
