import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import {
  setInviteFriendsStatus,
  setShowCashierPopup,
  setShowSearchPopup,
  setShowSideNav,
} from 'redux-thunk/redux/slices/settings.slice';
import { getItem, setItem } from 'helpers/localstorage.helpers';
import {
  // CRYPTO_NAME,
  ROUTE_PATHS,
  SHOW_SIDE_NAV,
  TOOLTIP_VARIANT,
} from 'constants/index';
import { getCasinoAllCategories } from 'redux-thunk/thunk/game.thunk';
import { openInfoToaster } from 'helpers/toaster.helpers';
import {
  NftMenuIcon,
  PokerMenuIcon,
  RankUpMenuIcon,
  FriendMenuIcon,
  PromotionMenuIcon,
  PartnerProgramMenuIcon,
  // CloseWhiteIcon,
  OptionLoadingIcon,
  SearchIconIcon,
  HeaderMenuIconIcon,
} from 'components/ui-kit/Icons/svg/index';
import { setSearchParam } from 'redux-thunk/redux/slices/sportsbook.slice';
import LiveValues from './components/LiveValues/index';
import { getCryptoCoins } from 'redux-thunk/thunk/crypto.thunk';
// import { getAuthToken } from 'helpers/cookie.helpers'
import { setCryptoCoin } from 'redux-thunk/redux/slices/cryptocoin.slice';
import CustomTooltip from 'components/ui-kit/Tooltip/index';
import SportsBookSection from './components/SportsBookSection/index';
import CasinoSection from './components/CasinoSection/index';
import CryptoSection from './components/CryptoSection/index';
import ResponsiveButtonBar from './components/ResponsiveButtonBar/index';
import { setShowLoginPopup } from 'redux-thunk/redux/slices/gameSetting.slice';
import OriginalsSection from './components/OriginalsSection/index';
import { LocalizedNavLink } from 'components/common/LocalizedLinks';
import {
  OriginalsGamesIcon,
  SportsMenuIcon,
  CryptoMenuIcon,
} from 'components/ui-kit/Icons/svg';
import './sideNavBar.scss';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
function SideNavbar() {
  const { t } = useTranslation();
  const { push } = useLocalizedNavigate();
  const dispatch = useDispatch();
  // const token = !!getAuthToken()
  const { width } = useWindowSize();
  const { pathname, search, location } = useLocation();
  const { showSideNav } = useSelector((state) => state?.settings);
  const { loginData } = useSelector((state) => state.auth);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [showCasinoOptions, setShowCasinoOptions] = useState(false);
  const [showSportsbookOptions, setShowSportsbookOptions] = useState(false);
  const [showOriginalsOptions, setShowOriginalsOptions] = useState(false);
  const [showCryptoOptions, setShowCryptoOptions] = useState(false);
  const { cryptoCoinsList } = useSelector((state) => state.crypto);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  const sidenavExpand = useCallback(() => {
    setItem(SHOW_SIDE_NAV, !showSideNav);
    dispatch(setShowSideNav(!showSideNav));
    document
      .getElementsByClassName('sidebar-wrap')[0]
      ?.classList.toggle('sidebar-close');
    document
      .getElementsByClassName('page-container')[0]
      ?.classList.toggle('sidebar-close');
    document
      .getElementsByClassName('header')[0]
      ?.classList.toggle('sidebar-close');
  }, [showSideNav]);

  useEffect(() => {
    if (isMobile) {
      dispatch(setShowSideNav(false));
    }
  }, [pathname]);

  useEffect(() => {
    const langCode = window.location.pathname.substring(0, 3);
    const purePathname = window.location.pathname.replace(langCode, '');
    if (purePathname === ROUTE_PATHS.SPORTS_BOOK) {
      dispatch(setSearchParam(search?.slice(12)));
    }
  }, [search]);

  useEffect(() => {
    if (!showSideNav) {
      setShowCasinoOptions(false);
      setShowSportsbookOptions(false);
      setShowCryptoOptions(false);
    }
  }, [showSideNav]);

  useEffect(() => {
    if (document?.body) {
      if (showSideNav && width <= 767) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = '';
      }
    }
  }, [showSideNav, width]);

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  useEffect(() => {
    if (windowWidth <= 1199) {
      setItem(SHOW_SIDE_NAV, false);
      dispatch(setShowSideNav(false));
    }
  }, [windowHeight, windowWidth]);

  useEffect(() => {
    // if (token) {
    dispatch(getCryptoCoins());
    // }
  }, []);

  useEffect(() => {
    if (typeof SHOW_SIDE_NAV === 'boolean') {
      dispatch(setShowSideNav(getItem(SHOW_SIDE_NAV)));
    }
    dispatch(getCasinoAllCategories());
  }, []);

  useEffect(() => {
    const id = pathname.includes('crypto') && pathname.split('/')[2];
    const cryptoSymbol = cryptoCoinsList?.filter((item) => item.id === id)?.[0];
    id &&
      dispatch(
        setCryptoCoin({
          name: cryptoSymbol?.name,
          symbol: cryptoSymbol?.symbol,
          id: id,
        })
      );
  }, [cryptoCoinsList]);

  // Listen for URL changes, including those from pushState
  useEffect(() => {
    if (window.location.pathname.includes('/providers/')) {
      // Update URL on initial load and when location changes
      setCurrentUrl(window.location.href);

      // Function to handle popstate events and manual pushState calls
      const handleUrlChange = () => {
        setCurrentUrl(window.location.href);
      };

      // Listen for popstate events (back/forward navigation)
      window.addEventListener('popstate', handleUrlChange);

      // Create a custom event for pushState
      const originalPushState = window.history.pushState;
      window.history.pushState = function () {
        originalPushState.apply(this, arguments);
        // Dispatch a custom event after pushState
        window.dispatchEvent(new Event('locationchange'));
      };

      // Listen for our custom pushState event
      window.addEventListener('locationchange', handleUrlChange);

      return () => {
        window.removeEventListener('popstate', handleUrlChange);
        window.removeEventListener('locationchange', handleUrlChange);
        // Restore original pushState
        window.history.pushState = originalPushState;
      };
    }
  }, [window.location.pathname]);

  return (
    <>
      <div className={`sidebar-wrap ${!showSideNav ? 'sidebar-close' : ''}`}>
        <div className="sidebar-content-wrap w-100">
          {/* <div className='mob-menu-header d-flex align-items-center justify-content-end '>
            <h3>{t('menu')}</h3>
            <span onClick={sidenavExpand}>
              <CloseWhiteIcon />
            </span>
          </div> */}
          <LiveValues />
          <div className="after-login-sidebar-content d-none d-xxl-none p-3">
            <div className="d-flex">
              <button
                className="btn btn-secondary w-100 text-uppercase"
                onClick={() => {
                  if (loginData?.accessToken) {
                    dispatch(setShowCashierPopup(true));
                  } else {
                    dispatch(setShowLoginPopup(true));
                  }
                }}
              >
                {t('cashier')}
              </button>
            </div>
          </div>

          <div className="sidebar-menu-list">
            <h4 className="sidevar-menu-heading">{t('mainMenu')}</h4>
            <ul>
              <li>
                {!showSideNav && (
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(setShowSearchPopup(true));
                    }}
                  >
                    <CustomTooltip
                      variant={
                        showSideNav
                          ? TOOLTIP_VARIANT.TOP_RIGHT
                          : TOOLTIP_VARIANT.SIDE_BAR
                      }
                      message={t('searchGames')}
                      showTooltip
                    >
                      <div className="menu-left d-flex align-items-center">
                        <div className="menu-icon menu-search-icon">
                          <SearchIconIcon />
                        </div>
                      </div>
                    </CustomTooltip>
                  </a>
                )}
              </li>
              <OriginalsSection
                showOriginalsOptions={showOriginalsOptions}
                setShowOriginalsOptions={setShowOriginalsOptions}
              />
              <CasinoSection
                showCasinoOptions={showCasinoOptions}
                setShowCasinoOptions={setShowCasinoOptions}
              />
              {/* <SportsBookSection
                setShowSportsbookOptions={setShowSportsbookOptions}
                showSportsbookOptions={showSportsbookOptions}
              />
              <CryptoSection
                setShowCryptoOptions={setShowCryptoOptions}
                showCryptoOptions={showCryptoOptions}
                setShowCasinoOptions={setShowCasinoOptions}
                showCasinoOptions={showCasinoOptions}
              /> */}

              {/* <li>
                {showSideNav ? (
                  <CustomTooltip
                    variant={
                      showSideNav
                        ? TOOLTIP_VARIANT.TOP_RIGHT
                        : TOOLTIP_VARIANT.SIDE_BAR
                    }
                    message={t('comingSoon')}
                    showTooltip
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        openInfoToaster({ message: t('comingSoon') });
                      }}
                    >
                      <div className="menu-left d-flex align-items-center">
                        <div className="menu-icon">
                          <OriginalsGamesIcon />
                        </div>
                        <p className="m-0">{t('clutchOriginals')}</p>
                      </div>
                      <span className="menu-arrow menu-arrow-transparent loading-option">
                        <OptionLoadingIcon />
                      </span>
                    </a>
                  </CustomTooltip>
                ) : (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openInfoToaster({ message: t('comingSoon') });
                    }}
                  >
                    <CustomTooltip
                      variant={
                        showSideNav
                          ? TOOLTIP_VARIANT.TOP_RIGHT
                          : TOOLTIP_VARIANT.SIDE_BAR
                      }
                      message={t('clutchOriginals')}
                      showTooltip
                    >
                      <div className="menu-left d-flex align-items-center">
                        <div className="menu-icon">
                          <OriginalsGamesIcon />
                        </div>
                        <p className="m-0">{t('clutchOriginals')}</p>
                      </div>
                    </CustomTooltip>
                    <span className="menu-arrow menu-arrow-transparent loading-option">
                      <OptionLoadingIcon />
                    </span>
                  </a>
                )}
              </li> */}

              <li>
                {showSideNav ? (
                  <CustomTooltip
                    variant={
                      showSideNav
                        ? TOOLTIP_VARIANT.TOP_RIGHT
                        : TOOLTIP_VARIANT.SIDE_BAR
                    }
                    message={t('comingSoon')}
                    showTooltip
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        openInfoToaster({ message: t('comingSoon') });
                      }}
                    >
                      <div className="menu-left d-flex align-items-center">
                        <div className="menu-icon">
                          <SportsMenuIcon />
                        </div>
                        <p className="m-0">{t('sports')}</p>
                      </div>
                      <span className="menu-arrow menu-arrow-transparent loading-option">
                        <OptionLoadingIcon />
                      </span>
                    </a>
                  </CustomTooltip>
                ) : (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openInfoToaster({ message: t('comingSoon') });
                    }}
                  >
                    <CustomTooltip
                      variant={
                        showSideNav
                          ? TOOLTIP_VARIANT.TOP_RIGHT
                          : TOOLTIP_VARIANT.SIDE_BAR
                      }
                      message={t('sports')}
                      showTooltip
                    >
                      <div className="menu-left d-flex align-items-center">
                        <div className="menu-icon">
                          <SportsMenuIcon />
                        </div>
                        <p className="m-0">{t('sports')}</p>
                      </div>
                    </CustomTooltip>
                    <span className="menu-arrow menu-arrow-transparent loading-option">
                      <OptionLoadingIcon />
                    </span>
                  </a>
                )}
              </li>

              <li>
                {showSideNav ? (
                  <CustomTooltip
                    variant={
                      showSideNav
                        ? TOOLTIP_VARIANT.TOP_RIGHT
                        : TOOLTIP_VARIANT.SIDE_BAR
                    }
                    message={t('comingSoon')}
                    showTooltip
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        openInfoToaster({ message: t('comingSoon') });
                      }}
                    >
                      <div className="menu-left d-flex align-items-center">
                        <div className="menu-icon">
                          <CryptoMenuIcon />
                        </div>
                        <p className="m-0">{t('crypto')}</p>
                      </div>
                      <span className="menu-arrow menu-arrow-transparent loading-option">
                        <OptionLoadingIcon />
                      </span>
                    </a>
                  </CustomTooltip>
                ) : (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openInfoToaster({ message: t('comingSoon') });
                    }}
                  >
                    <CustomTooltip
                      variant={
                        showSideNav
                          ? TOOLTIP_VARIANT.TOP_RIGHT
                          : TOOLTIP_VARIANT.SIDE_BAR
                      }
                      message={t('crypto')}
                      showTooltip
                    >
                      <div className="menu-left d-flex align-items-center">
                        <div className="menu-icon">
                          <CryptoMenuIcon />
                        </div>
                        <p className="m-0">{t('crypto')}</p>
                      </div>
                    </CustomTooltip>
                    <span className="menu-arrow menu-arrow-transparent loading-option">
                      <OptionLoadingIcon />
                    </span>
                  </a>
                )}
              </li>

              <li>
                {showSideNav ? (
                  <CustomTooltip
                    variant={
                      showSideNav
                        ? TOOLTIP_VARIANT.TOP_RIGHT
                        : TOOLTIP_VARIANT.SIDE_BAR
                    }
                    message={t('comingSoon')}
                    showTooltip
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        openInfoToaster({ message: t('comingSoon') });
                      }}
                    >
                      <div className="menu-left d-flex align-items-center">
                        <div className="menu-icon">
                          <NftMenuIcon />
                        </div>
                        <p className="m-0">{t('NFT')}</p>
                      </div>
                      <span className="menu-arrow menu-arrow-transparent loading-option">
                        <OptionLoadingIcon />
                      </span>
                    </a>
                  </CustomTooltip>
                ) : (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openInfoToaster({ message: t('comingSoon') });
                    }}
                  >
                    <CustomTooltip
                      variant={
                        showSideNav
                          ? TOOLTIP_VARIANT.TOP_RIGHT
                          : TOOLTIP_VARIANT.SIDE_BAR
                      }
                      message={t('NFT')}
                      showTooltip
                    >
                      <div className="menu-left d-flex align-items-center">
                        <div className="menu-icon">
                          <NftMenuIcon />
                        </div>
                        <p className="m-0">{t('NFT')}</p>
                      </div>
                    </CustomTooltip>
                    <span className="menu-arrow menu-arrow-transparent loading-option">
                      <OptionLoadingIcon />
                    </span>
                  </a>
                )}
              </li>
              <li>
                {showSideNav ? (
                  <CustomTooltip
                    variant={
                      showSideNav
                        ? TOOLTIP_VARIANT.TOP_RIGHT
                        : TOOLTIP_VARIANT.SIDE_BAR
                    }
                    message={showSideNav ? t('comingSoon') : t('poker')}
                    showTooltip
                  >
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        openInfoToaster({ message: 'Coming Soon!' });
                      }}
                    >
                      <div className="menu-left d-flex align-items-center">
                        <div className="menu-icon">
                          <PokerMenuIcon />
                        </div>
                        <p className="m-0">{t('poker')}</p>
                      </div>
                      <span className="menu-arrow menu-arrow-transparent loading-option">
                        <OptionLoadingIcon />
                      </span>
                    </a>
                  </CustomTooltip>
                ) : (
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      openInfoToaster({ message: 'Coming Soon!' });
                    }}
                  >
                    <CustomTooltip
                      variant={
                        showSideNav
                          ? TOOLTIP_VARIANT.TOP_RIGHT
                          : TOOLTIP_VARIANT.SIDE_BAR
                      }
                      message={showSideNav ? t('comingSoon') : t('poker')}
                      showTooltip
                    >
                      <div className="menu-left d-flex align-items-center">
                        <div className="menu-icon">
                          <PokerMenuIcon />
                        </div>
                        <p className="m-0">{t('poker')}</p>
                      </div>
                    </CustomTooltip>
                    <span className="menu-arrow menu-arrow-transparent loading-option">
                      <OptionLoadingIcon />
                    </span>
                  </a>
                )}
              </li>
            </ul>
            <h4 className="sidevar-menu-heading sidebar-bottom-title">
              {t('earnRewards')}
            </h4>
            <ul>
              <li>
                <LocalizedNavLink
                  to={
                    loginData?.accessToken
                      ? ROUTE_PATHS.REWARDS
                      : ROUTE_PATHS.PRE_LOGIN_REWARDS
                  }
                >
                  <CustomTooltip
                    variant={
                      showSideNav
                        ? TOOLTIP_VARIANT.TOP_RIGHT
                        : TOOLTIP_VARIANT.SIDE_BAR
                    }
                    message={t('rankUp')}
                    showTooltip={!showSideNav}
                  >
                    <div className="menu-left d-flex align-items-center">
                      <div className="menu-icon">
                        <RankUpMenuIcon />
                      </div>
                      <p className="m-0">{t('rankUp')}</p>
                    </div>
                  </CustomTooltip>
                </LocalizedNavLink>
              </li>
              <li>
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    if (loginData?.accessToken) {
                      dispatch(setShowCashierPopup(true));
                      dispatch(setInviteFriendsStatus(true));
                    } else {
                      push(ROUTE_PATHS.PRE_LOGIN_AFFILIATES);
                    }
                  }}
                >
                  <CustomTooltip
                    variant={
                      showSideNav
                        ? TOOLTIP_VARIANT.TOP_RIGHT
                        : TOOLTIP_VARIANT.SIDE_BAR
                    }
                    message={t('inviteFriends')}
                    showTooltip={!showSideNav}
                  >
                    <div className="menu-left d-flex align-items-center">
                      <div className="menu-icon">
                        <FriendMenuIcon />
                      </div>
                      <p className="m-0">{t('inviteFriends')}</p>
                    </div>
                  </CustomTooltip>
                </a>
              </li>
              <li>
                <LocalizedNavLink to={ROUTE_PATHS.PROMOTIONS}>
                  <CustomTooltip
                    variant={
                      showSideNav
                        ? TOOLTIP_VARIANT.TOP_RIGHT
                        : TOOLTIP_VARIANT.SIDE_BAR
                    }
                    message={t('accountMenuPromotions')}
                    showTooltip={!showSideNav}
                  >
                    <div className="menu-left d-flex align-items-center">
                      <div className="menu-icon">
                        <PromotionMenuIcon />
                      </div>
                      <p className="m-0">{t('accountMenuPromotions')}</p>
                    </div>
                  </CustomTooltip>
                </LocalizedNavLink>
              </li>
              <li>
                {showSideNav ? (
                  <CustomTooltip
                    variant={
                      showSideNav
                        ? TOOLTIP_VARIANT.TOP_RIGHT
                        : TOOLTIP_VARIANT.SIDE_BAR
                    }
                    message={
                      showSideNav ? t('comingSoon') : t('partnerProgram')
                    }
                    showTooltip
                  >
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        openInfoToaster({ message: t('comingSoon') });
                      }}
                    >
                      <div className="menu-left d-flex align-items-center">
                        <div className="menu-icon">
                          <PartnerProgramMenuIcon />
                        </div>
                        <p className="m-0">{t('partnerProgram')}</p>
                      </div>
                      <span className="menu-arrow menu-arrow-transparent loading-option">
                        <OptionLoadingIcon />
                      </span>
                    </a>
                  </CustomTooltip>
                ) : (
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      openInfoToaster({ message: t('comingSoon') });
                    }}
                  >
                    <CustomTooltip
                      variant={
                        showSideNav
                          ? TOOLTIP_VARIANT.TOP_RIGHT
                          : TOOLTIP_VARIANT.SIDE_BAR
                      }
                      message={
                        showSideNav ? t('comingSoon') : t('partnerProgram')
                      }
                      showTooltip
                    >
                      <div className="menu-left d-flex align-items-center">
                        <div className="menu-icon">
                          <PartnerProgramMenuIcon />
                        </div>
                        <p className="m-0">{t('partnerProgram')}</p>
                      </div>
                    </CustomTooltip>
                    <span className="menu-arrow menu-arrow-transparent loading-option">
                      <OptionLoadingIcon />
                    </span>
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
      {!currentUrl?.includes('gameLaunched=true') && (
        <ResponsiveButtonBar sidenavExpand={sidenavExpand} />
      )}
    </>
  );
}

export default React.memo(SideNavbar);
