import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routes from './routesList';
import RouteValidator from './RouteValidator';
import NotFoundComponent from 'components/layout/404/index';
import SideNavBar from 'components/layout/SideNavBar/index';
import Header from 'components/layout/Header/index';
import { LOADER_HANDLER_TYPES } from 'constants/index';
import MainLoader from 'components/ui-kit/Loader/MainLoader/index';
import Loader from 'components/ui-kit/Loader/index';
import GoogleTagManager from 'components/googleTagManager';
import LanguageRoute from 'components/LanguageRoute';
import { updateHtmlLang } from 'helpers/language.helpers';
const CustomRoutes = () => {
  const location = useLocation();
  const isLobby = location.pathname === '/dashboard-lobby';
  const { [LOADER_HANDLER_TYPES.page]: loading } = useSelector(
    (state) => state.loader
  );
  const language = useSelector((state) => state.auth.language);
  useEffect(() => {
    updateHtmlLang(language);
  }, [language]);

  return (
    <LanguageRoute>
      {!isLobby && (
        <>
          <SideNavBar />
          <Header />
        </>
      )}
      {loading && <MainLoader />}
      <div id={!isLobby && 'urm-chat-container'}>
        <Suspense fallback={<Loader />}>
          <Switch>
            {routes.map((route) => {
              // Handle array of paths
              if (Array.isArray(route.path)) {
                return route.path.map((path) => (
                  <Route
                    key={path}
                    path={`/:lang?${path}`}
                    exact
                    render={(props) => (
                      <RouteValidator {...props} route={{ ...route, path }} />
                    )}
                  />
                ));
              }
              // Handle single path
              return (
                <Route
                  key={route.path}
                  path={`/:lang?${route.path}`}
                  exact
                  render={(props) => {
                    return <RouteValidator {...props} route={route} />;
                  }}
                />
              );
            })}
            <Route component={NotFoundComponent} />
          </Switch>
        </Suspense>
      </div>
      <GoogleTagManager />
    </LanguageRoute>
  );
};

export default CustomRoutes;
