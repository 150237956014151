import { SuccessMessage } from 'network/messages/successMessages';
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index';
import { microServices } from 'network/apis/microservice/index';
import axiosInstanceService from 'network/apis/index';

/**
 * Service to do login
 * @param {object} data - object contains username and password
 */
export const loginService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/user/login', data, {
    server: microServices.SERVICE_URL_1,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.login,
  });
};

/**
 * Service to do logout
 */
export const logoutService = () => {
  return axiosInstanceService(
    METHOD_TYPES.post,
    '/user/logout',
    {},
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: SuccessMessage.logout,
    }
  );
};

export const signUpService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/user/signup', data, {
    server: microServices.SERVICE_URL_1,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.signup,
  });
};

/**
 * Service to change password
 * @param {object} data - object contains old and new password
 */
export const updatePasswordService = (data) => {
  return axiosInstanceService(METHOD_TYPES.put, '/user/change-password', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword,
  });
};

export const forgetPasswordService = (data) => {
  return axiosInstanceService(
    METHOD_TYPES.post,
    '/user/forgot-password',
    data,
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.submit,
      successMessage: SuccessMessage.tokenVerify,
    }
  );
};
export const forgetVerifyPasswordService = (data) => {
  return axiosInstanceService(
    METHOD_TYPES.post,
    '/user/verify-password-token',
    data,
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.submit,
      successMessage: SuccessMessage.resetPassword,
    }
  );
};

export const verifyEmailService = (data) => {
  return axiosInstanceService(
    METHOD_TYPES.post,
    '/user/verify-email-token',
    data,
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: SuccessMessage.emailVerify,
    }
  );
};

export const verifyOtpService = (data) => {
  return axiosInstanceService(
    METHOD_TYPES.put,
    '/user/verify-email-otp',
    data,
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.submit,
      successMessage: SuccessMessage.otpVerified,
    }
  );
};

export const checkUserAddress = (data) => {
  return axiosInstanceService(
    METHOD_TYPES.post,
    '/user/check-user-address',
    data,
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.submit,
    }
  );
};

export const getMetaUser = (data) => {
  return axiosInstanceService(
    METHOD_TYPES.post,
    '/user/login-with-metaMask',
    data,
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.submit,
      successMessage: SuccessMessage.login,
    }
  );
};

export const loginWithOtpService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/user/login', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword,
  });
};

export const verifyMetaSignature = (data) => {
  return axiosInstanceService(
    METHOD_TYPES.post,
    '/user/verify-metaSignature',
    data,
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.submit,
      successMessage: SuccessMessage.changePassword,
    }
  );
};

export const updateEmailService = (data) => {
  return axiosInstanceService(METHOD_TYPES.put, '/user/update-email', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.emailUpdateOtp,
  });
};

export const updateEmailForFirstTimeService = (data) => {
  return axiosInstanceService(
    METHOD_TYPES.put,
    '/user/update-metamask-email',
    data,
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.submit,
      successMessage: SuccessMessage.emailUpdateOtp,
    }
  );
};

export const loginWithGoogleService = (data) => {
  return axiosInstanceService(
    METHOD_TYPES.post,
    'user/login-authenticated-user-with-google',
    data,
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.submit,
      successMessage: SuccessMessage.login,
    }
  );
};

export const loginWithTwitchService = (data) => {
  return axiosInstanceService(
    METHOD_TYPES.post,
    '/user/login-authenticated-user-with-twitch',
    data,
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.submit,
      successMessage: SuccessMessage.login,
    }
  );
};

export const loginWithPhantomService = (data) => {
  return axiosInstanceService(
    METHOD_TYPES.post,
    '/user/login-with-phantom',
    data,
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.submit,
      successMessage: SuccessMessage.login,
    }
  );
};

export const loginWithTelegramService = (data) => {
  return axiosInstanceService(
    METHOD_TYPES.post,
    'user/login-authenticated-user-with-telegram',
    data,
    {
      server: microServices.SERVICE_URL_1,
      loader: LOADER_HANDLER_TYPES.submit,
      successMessage: SuccessMessage.login,
    }
  );
};

export const signUpBetaService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/user/beta-signup', data, {
    server: microServices.SERVICE_URL_1,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.signup,
  });
};
