import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LANGUAGES } from 'constants/index';

export const updateHtmlLang = (languageCode) => {
  const language = LANGUAGES.find((lang) => lang.languageCode === languageCode);
  if (language?.apiCode) {
    document.documentElement.lang = language.apiCode;
  }
};
