import { createSlice } from '@reduxjs/toolkit';
import { generateMoonpaySignature } from 'redux-thunk/thunk/moonpay.thunk';

const initialState = {
  signature: null,
  loading: false,
};

const moonpaySlice = createSlice({
  name: 'moonpaySlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(generateMoonpaySignature.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(generateMoonpaySignature.fulfilled, (state, action) => {
      return {
        ...state,
        signature: action.payload,
        loading: false,
      };
    });
    builder.addCase(generateMoonpaySignature.rejected, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
  },
});

// export const { } = moonpaySlice.actions

export default moonpaySlice.reducer;
