import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GamesProviderHeadingIcon } from 'components/ui-kit/Icons/svg/index';
import { provider1 } from 'components/ui-kit/Icons/png/index';
import { setViewAllGames } from 'redux-thunk/redux/slices/games.slice';
import { GameImg } from 'pages/GameImage/index';
import { ROUTE_PATHS } from 'constants/index';
import { getCasinoProvidersList } from 'redux-thunk/thunk/game.thunk';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import ContentHeader from '../../../../../../components/ui-kit/ContentHeader';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
const ProvidersListing = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useLocalizedNavigate();
  const { providers } = useSelector((state) => state.games);
  const LIMIT = 30;

  useEffect(() => {
    if (!providers) {
      dispatch(getCasinoProvidersList({ offset: 0, limit: LIMIT }));
    }
  }, []);

  const swiperSettings = useMemo(
    () => ({
      modules: [Navigation],
      loop: false,
      slidesPerGroup: 1,
      slidesPerGroupAuto: true,
      navigation: {
        nextEl: '.providersListing-next',
        prevEl: '.providersListing-prev',
      },
      slidesPerView: 'auto',
    }),
    []
  );

  return (
    <section className="provider-section">
      <ContentHeader
        title={t('providers')}
        subtitle={t('viewAll')}
        Icon={GamesProviderHeadingIcon}
        subtitleOnClickHandler={(e) => {
          e.preventDefault();
          push(ROUTE_PATHS.PROVIDERS);
          dispatch(setViewAllGames(null));
        }}
        id="providersListing"
      />
      <div className="providers-swiper">
        <Swiper {...swiperSettings}>
          {providers?.rows?.map((item) => (
            <SwiperSlide
              key={item?.providerId}
              onClick={(e) => {
                e?.preventDefault();
                push(`${ROUTE_PATHS.PROVIDERS}/${item?.name}`, {
                  providerId: item?.providerId,
                });
              }}
            >
              <div className="theme-card">
                <div className="provider-logo">
                  <GameImg imgSrc={item?.logo ? item?.logo : provider1} />
                </div>
                <div className="provider-badge-wrap">
                  {item?.gameCount} {t('games')}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default React.memo(ProvidersListing);
