import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { casino, heart } from 'components/ui-kit/Icons/png/index';
import { getGames } from 'redux-thunk/thunk/casino.thunk';
import {
  addFavouriteGame,
  getAllFavouriteGames,
  getCasinoProvidersList,
  removeFavouriteGame,
} from 'redux-thunk/thunk/game.thunk';
import {
  GAME_CATEGORY,
  LOADER_HANDLER_TYPES,
  SORT_BY_OPTIONS,
  SORT_BY_OPTIONS_MOBILE,
} from 'constants/index';
import Loader from 'components/ui-kit/Loader/index';
import { setShowSearchPopup } from 'redux-thunk/redux/slices/settings.slice';
import ProvidersSearchBar from '../ProvidersSearchBar/index';
import ContentNotFound from 'components/ui-kit/ContentNotFound/index';
import CommonPopup from 'components/ui-kit/CommonPopup/index';
import AllGamesCard from 'components/ui-kit/AllGamesCard/index';
import { useLoginBeforeRedirect } from 'hooks/useLoginBeforeRedirect';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
const SearchPopup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useLocalizedNavigate();
  const { user } = useSelector((state) => state.user);
  const { casinoCategoriesList, favouriteGames } = useSelector(
    (state) => state.games
  );
  const { viewAllGames } = useSelector((state) => state.searchPopup);
  const { loginData } = useSelector((state) => state.auth);
  const {
    [LOADER_HANDLER_TYPES.categoryWiseGamesList]: loading,
    [LOADER_HANDLER_TYPES.submit]: actionLoading,
  } = useSelector((state) => state.loader);
  const [items, setItems] = useState();
  const [type, setType] = useState('');
  const [provider, setProvider] = useState('');
  const [name, setName] = useState('');
  const [gameId, setGameId] = useState(null);
  const [sortBy, setSortBy] = useState(
    window?.innerWidth < 470
      ? Object.keys(SORT_BY_OPTIONS_MOBILE)[0]
      : Object.keys(SORT_BY_OPTIONS)[0]
  );
  const { handleLoginBeforeRedirect } = useLoginBeforeRedirect();

  const limit = 35;

  const modalCloseHandler = () => {
    dispatch(setShowSearchPopup(false));
  };

  const favouriteGameHandler = (e, game) => {
    setGameId(game?.id);
    e?.stopPropagation();
    if (game?.isFavourite || type === GAME_CATEGORY.FAVOURITE) {
      dispatch(removeFavouriteGame({ game }));
    } else {
      dispatch(addFavouriteGame({ game }));
    }
  };

  const onGamePlayRedirect = useCallback(
    (game) => {
      const gameName = game.gameName.toLowerCase().replace(/\s+/g, '-');
      if (loginData?.accessToken) {
        push(
          `/casino/providers/${game?.GameProvider?.name}/${gameName}-${game?.id}`,
          { game, demo: false }
        );
        dispatch(setShowSearchPopup(false));
      } else if (game.hasDemo) {
        push(
          `/casino/providers/${game?.GameProvider?.name}/${gameName}-${game?.id}?demo=true`,
          { game, demo: true }
        );
        dispatch(setShowSearchPopup(false));
      } else {
        handleLoginBeforeRedirect(
          `/casino/providers/${game?.GameProvider?.name}/${gameName}-${game?.id}`
        );
      }
    },
    [loginData]
  );

  const fetchMore = () => {
    if (type === GAME_CATEGORY.FAVOURITE) {
      dispatch(
        getAllFavouriteGames({
          data: {
            limit,
            offset: items?.rows?.length,
            provider,
            name,
            sortBy:
              window?.innerWidth < 470
                ? SORT_BY_OPTIONS_MOBILE[sortBy]
                : SORT_BY_OPTIONS[sortBy],
          },
        })
      );
    } else {
      dispatch(
        getGames({
          data: {
            limit,
            offset: items?.rows?.length,
            type,
            provider,
            userId: user?.id,
            name,
            sortBy:
              window?.innerWidth < 470
                ? SORT_BY_OPTIONS_MOBILE[sortBy]
                : SORT_BY_OPTIONS[sortBy],
          },
        })
      );
    }
  };

  useEffect(() => {
    if (type === GAME_CATEGORY.FAVOURITE) {
      setItems({
        ...favouriteGames,
        rows: favouriteGames?.rows?.map((el) => ({
          ...el,
          CasinoGame: { ...el?.CasinoGame, isFavourite: true },
        })),
      });
    } else {
      setItems(viewAllGames);
    }
  }, [viewAllGames, favouriteGames]);

  useEffect(() => {
    if (type === GAME_CATEGORY.FAVOURITE) {
      dispatch(
        getAllFavouriteGames({
          data: {
            limit,
            offset: 0,
            provider,
            name,
            sortBy:
              window?.innerWidth < 470
                ? SORT_BY_OPTIONS_MOBILE[sortBy]
                : SORT_BY_OPTIONS[sortBy],
          },
        })
      );
    } else {
      dispatch(
        getGames({
          data: {
            limit,
            offset: 0,
            type,
            provider,
            userId: user?.id,
            name,
            sortBy:
              window?.innerWidth < 470
                ? SORT_BY_OPTIONS_MOBILE[sortBy]
                : SORT_BY_OPTIONS[sortBy],
          },
        })
      );
    }
  }, [type, name, provider, sortBy]);

  useEffect(() => {
    dispatch(getCasinoProvidersList({ orderBy: 'name' }));
    const body = document.body;
    body?.classList.add('overflowHidden');
    return () => {
      body?.classList.remove('overflowHidden');
    };
  }, []);

  return (
    <CommonPopup
      modalCloseHandler={modalCloseHandler}
      id="search_modal"
      modalBodyClasses="p-0"
      modalDialogClass="search-dilog"
      modalContentClass="search-modal"
    >
      <div className="search-modal-wrap">
        <div className="row">
          <div className="col-lg-2 d-none d-lg-block p-0">
            <div
              className="nav flex-column nav-pills profile-tabs w-100"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                className={`nav-link ${type === '' ? 'active' : ''}`}
                id="v-pills-all-games-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-all-games"
                type="button"
                role="tab"
                aria-controls="v-pills-all-games"
                aria-selected="true"
                onClick={() => setType('')}
              >
                <img src={casino} alt="favourites-icon" />
                {t('allGames')}
              </button>
              {loginData?.accessToken && (
                <button
                  className={`nav-link ${
                    type === GAME_CATEGORY.FAVOURITE ? 'active' : ''
                  }`}
                  id="v-pills-favourites-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-favourites"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-favourites"
                  aria-selected="false"
                  onClick={() => {
                    setType(GAME_CATEGORY.FAVOURITE);
                  }}
                >
                  <img src={heart} alt="favourites-icon" />
                  {t('favourites')}
                </button>
              )}
              {casinoCategoriesList?.map((item) => (
                <button
                  key={uuidv4()}
                  className={`nav-link ${
                    type === item?.gameCategory ? 'active' : ''
                  }`}
                  id="v-pills-messages-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-messages"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-messages"
                  aria-selected="false"
                  onClick={() => setType(item?.gameCategory)}
                >
                  <img src={item?.image} alt={item?.gameCategory} />
                  {startCase(item?.gameCategory)}
                </button>
              ))}
            </div>
          </div>
          <div className="col-lg-10 col-12 p-0">
            <div className="search-modal-right-section">
              <section className="search-modal-heading">
                <h4>{t('searchGames')}</h4>
              </section>
              <ProvidersSearchBar
                setName={setName}
                setProvider={setProvider}
                provider={provider}
                showProvidersDropdown
                sortBy={sortBy}
                setSortBy={setSortBy}
              />
              <section className="global-casino-section">
                {items?.rows?.length === 0 && !loading ? (
                  <ContentNotFound message={t('noGamesFound')} />
                ) : (
                  <div className="row">
                    {items?.rows?.map((game) => {
                      const item =
                        type === GAME_CATEGORY.FAVOURITE
                          ? game?.CasinoGame
                          : game;
                      return (
                        <AllGamesCard
                          key={item?.casinoGameId}
                          game={item}
                          item={item}
                          onGamePlayRedirect={onGamePlayRedirect}
                          isLoggedIn={loginData?.accessToken}
                          favouriteGameHandler={favouriteGameHandler}
                          gameId={gameId}
                          actionLoading={actionLoading}
                          isFavourite={item?.isFavourite}
                          searchPopup
                          cardClasses="col-4 col-md-3 col-lg-2 px-1"
                        />
                      );
                    })}
                  </div>
                )}
                {loading && <Loader variant={LOADER_HANDLER_TYPES.content} />}
                <div className="load-more-wrap text-center my-3">
                  <button
                    type="button"
                    className={`btn btn-secondary text-uppercase ${
                      items?.rows?.length < items?.count ? '' : 'd-none'
                    }`}
                    onClick={fetchMore}
                    disabled={!(items?.rows?.length < items?.count)}
                  >
                    {t('loadMore')}
                  </button>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </CommonPopup>
  );
};

export default React.memo(SearchPopup);
