import React, { useEffect, useState } from 'react';
import _PaymentIQCashier from 'paymentiq-cashier-bootstrapper';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DepositCurrencySection from './DepositCurrencySection';
import { NavLink } from 'react-router-dom';
import CashCard from 'components/ui-kit/CashCard';
import DepositBuyCrypto from './DepositBuyCrypto';
import Loader from 'components/ui-kit/Loader/index';
import { getPaymentInitialize } from 'redux-thunk/thunk/user.thunk';
import { LocalizedNavLink } from 'components/common/LocalizedLinks';
import { set } from 'lodash';
import { CASHIER_PAYMENT_TABS, LOADER_HANDLER_TYPES } from 'constants/index';

const CashierDeposit = ({
  showMoonPayWidget,
  insideTab,
  setInsideTab,
  loading,
  setLoading,
  disabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedWallet, setSelectedWallet] = useState(null);
  const { user } = useSelector((state) => state.user);
  const { inviteFriendsStatus } = useSelector((state) => state.settings);

  const PaymentIQCashierInit = (merchantId, userId, sessionId, mode, rest) => {
    window._PaymentIQCashierReset();
    return new _PaymentIQCashier(
      '#cashier-deposit',
      {
        merchantId: merchantId,
        userId: userId,
        sessionId: sessionId,
        environment: mode,
        method: 'deposit',
        fetchConfig: true,
        locale: 'en',
        attributes: { ...rest },
      },
      (api) => {
        setLoading(false);
        api.on({
          cashierInitLoad: () => {},
          success: (data) => {},
          error: (error) => {},
        });
      }
    );
  };

  useEffect(() => {
    if (insideTab === CASHIER_PAYMENT_TABS.PAYMENT_IQ) {
      dispatch(
        getPaymentInitialize({ method: 'deposit', currency: 'USD' })
      ).then((res) => {
        const { userId, sessionId, merchantId, merchantMode, ...rest } =
          res.payload;
        PaymentIQCashierInit(merchantId, userId, sessionId, merchantMode, rest);
      });
    }
  }, [insideTab]);

  useEffect(() => {
    if (selectedWallet) {
      const [wallet] = user?.wallets?.filter(
        (wallet) => wallet?.currencyId === selectedWallet?.currencyId
      );
      setSelectedWallet(wallet);
    }
  }, [user]);

  useEffect(() => {
    return () => {
      setSelectedWallet(null);
    };
  }, []);

  return (
    <div
      className={`tab-pane fade ${inviteFriendsStatus ? '' : 'show active'}`}
      id="pills-cashier-deposit"
      role="tabpanel"
      aria-labelledby="pills-cashier-deposit-tab"
      tabIndex="0"
    >
      {!selectedWallet && (
        <ul
          className="nav nav-pills nav-scroll-smooth mb-3 full-width"
          role="tablist"
        >
          <li
            className={`nav-link ${
              insideTab === CASHIER_PAYMENT_TABS.DEPOSIT_OPTIONS ? 'active' : ''
            }`}
            role="presentation"
          >
            <LocalizedNavLink
              to="#"
              onClick={(e) => {
                setInsideTab(CASHIER_PAYMENT_TABS.DEPOSIT_OPTIONS);
                window._PaymentIQCashierReset();
              }}
            >
              {t('crypto')}
            </LocalizedNavLink>
          </li>
          <li
            className={`nav-link ${
              insideTab === CASHIER_PAYMENT_TABS.PAYMENT_IQ ? 'active' : ''
            }`}
            role="presentation"
          >
            <LocalizedNavLink
              to="#"
              onClick={(e) => {
                setInsideTab(CASHIER_PAYMENT_TABS.PAYMENT_IQ);
                window._PaymentIQCashierReset();
              }}
            >
              {t('fiat')}
            </LocalizedNavLink>
          </li>
        </ul>
      )}
      {insideTab === CASHIER_PAYMENT_TABS.DEPOSIT_OPTIONS ? (
        !selectedWallet ? (
          <div className="deposit-step-one">
            {/* <div className="cashier-heading">
              <h3 className="mb-0 text-uppercase">{t('depositOptions')}</h3>
            </div> */}
            <div className="deposit-card-wrap">
              <div className="row g-1">
                {user?.wallets
                  ?.filter((wallet) => !wallet?.currency?.isFiat)
                  ?.filter((wallet) => wallet?.currency?.isDepositable)
                  ?.map((wallet) => (
                    <div key={wallet?.currencyId} className="col-4 mb-1 px-1">
                      <CashCard
                        onClick={() => {
                          setSelectedWallet(wallet);
                        }}
                        title={wallet.currency.name}
                        image={wallet.currency.image}
                        desc={wallet.currency.code}
                        enabled={!wallet?.currency?.disableDepositable}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <DepositBuyCrypto
              currencyName="Crypto"
              buttonType="secondary"
              showMoonPayWidget={showMoonPayWidget}
            />
          </div>
        ) : (
          <DepositCurrencySection
            selectedWallet={selectedWallet}
            setSelectedWallet={setSelectedWallet}
            showMoonPayWidget={showMoonPayWidget}
          />
        )
      ) : (
        <>
          <div id="cashier-deposit" />
        </>
      )}
    </div>
  );
};

export default CashierDeposit;
