import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import {
  setShowLoginPopup,
  setShowSignupPopup,
} from 'redux-thunk/redux/slices/gameSetting.slice';
import { yupResolver } from '@hookform/resolvers/yup';
import { userSignUp } from 'redux-thunk/thunk/auth.thunk';
import { openErrorToaster } from 'helpers/toaster.helpers';
import ErrorMessage from 'components/layout/ErrorMessage/index';
import PhoneInputField from 'components/ui-kit/PhoneInput/index';
import Loader from 'components/ui-kit/Loader/index';
import { LOADER_HANDLER_TYPES, ROUTE_PATHS } from 'constants/index';
import SocialLinks from '../SocialLinks/index';
import { useQuery } from 'hooks/useQuery';
import { EyeCloseIcon, EyeIcon } from 'components/ui-kit/Icons/svg/index';
import { LocalizedNavLink } from 'components/common/LocalizedLinks';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';

const Signup = ({
  setShowVerifyEmailPopup,
  showSignupPopup,
  registerTabActive,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useLocalizedNavigate();
  const location = useLocation();
  const query = useQuery();
  const [showPassword, setShowPassword] = useState({
    showPass: false,
    showConfirmPass: false,
  });
  // const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(
  //   (state) => state.loader
  // );

  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    userName: yup
      .string()
      .required(`${t('signupErrorsUserNameRequired')}`)
      .min(5, `${t('signupErrorsUserNameMinLength')}`)
      .max(50, `${t('signupErrorsUserNameMaxLength')}`)
      .matches(/^\w+$/, t('signupErrorsUserNamePattern')),
    firstName: yup.string().required(`${t('requiredField')}`),
    lastName: yup.string().required(`${t('requiredField')}`),
    phone: yup
      .string()
      .nullable()
      .test('phone', 'Enter valid phone', function (value: any) {
        if (value) {
          if (isValidPhoneNumber(value?.toString())) {
            return true;
          }
          if (value.length < 4) {
            return true;
          }
          return false;
        }
        return true;
      }),
    email: yup
      .string()
      .required(t('signupErrorsEmailRequired'))
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        t('signupErrorsEmailPattern')
      ),
    password: yup
      .string()
      .required(`${t('signupErrorsPasswordRequired')}`)
      .min(8, `${t('signupErrorsPasswordPattern')}`)
      .max(16, `${t('signupErrorsPasswordPattern')}`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\-~#\^()\[\]{}"':;<,>./|\\])[A-Za-z\d@$!%*?&\-~#\^()\[\]{}"':;<,>./|\\]{8,}$/,
        t('signupErrorsPasswordPattern')
      ),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const onSignupSuccess = (res) => {
    setLoading(false);
    if (window && window?.urmChat?.mergeProfile) {
      window?.urmChat
        ?.mergeProfile([window.urm_data?.appData?.profileId, res.user.id])
        .then(() => {
          setLoading(false);
          // reload the page and remove login query params
          window.location.href = location.pathname;
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      window.location.href = location.pathname;
    }
  };

  const onSignupError = (error) => {
    setLoading(false);
  };

  const handleRegisterSubmit = async (userDetails) => {
    const { phone, ...rest } = userDetails;
    setLoading(true);
    // const affiliateCode = query.get('affiliateCode');
    const referralCode = query.get('referralCode');
    // if (affiliateCode) {
    //   userDetails.affiliatedBy = affiliateCode;
    // }
    if (referralCode) {
      userDetails.referrerCode = referralCode;
      userDetails.referralLink = window?.location?.href;
    }
    const parsedPhone = parsePhoneNumber(phone);

    const newValue = {
      ...rest,
      phone: +parsedPhone?.nationalNumber,
      phoneCode: +parsedPhone?.countryCallingCode,
    };

    try {
      dispatch(
        userSignUp({
          userDetails: newValue,
          setShowVerifyEmailPopup,
          onSignupSuccess,
          onSignupError,
        })
      );
    } catch (e) {
      openErrorToaster({ message: e.message });
    }
  };

  useEffect(() => {
    return () => {
      const langCode = location.pathname.substring(0, 3);
      const purePathname = location.pathname.replace(langCode, '');

      if (purePathname === ROUTE_PATHS.SIGNUP) {
        push(ROUTE_PATHS.HOME);
      }
    };
  }, []);

  return (
    <div
      className={`tab-pane fade ${showSignupPopup && 'show active'}`}
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <div className="register-wrap pb-130">
        <form onSubmit={handleSubmit(handleRegisterSubmit)}>
          <div className="full">
            <div className="mb-3 form-group">
              <label htmlFor="user_name" className="form-label text-uppercase">
                {t('signupUsername')}
              </label>{' '}
              <span className="color-red">*</span>
              <input
                type="text"
                placeholder={t('signupUsername')}
                className={`form-control form-control-dark shadow-none ${
                  errors?.userName ? 'error-input' : ''
                }`}
                id="user_name"
                name="userName"
                {...register('userName')}
                aria-describedby=""
                maxLength={50}
              />
              {errors && errors.userName && (
                <div className="error-container">
                  <ErrorMessage
                    className="color-red text-danger error-msg login-input ms-2 d-flex"
                    message={errors.userName.message}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="half">
            <div className="mb-3 form-group">
              <label htmlFor="firstName" className="form-label text-uppercase">
                {t('firstName')}
              </label>
              <input
                type="text"
                placeholder={t('firstName')}
                className={`form-control form-control-dark shadow-none ${
                  errors?.firstName ? 'error-input' : ''
                }`}
                id="affiliate_code"
                name="firstName"
                required={false}
                {...register('firstName')}
                aria-describedby=""
                maxLength={50}
              />
              {errors && errors.firstName && (
                <div className="error-container">
                  <ErrorMessage
                    className="color-red text-danger error-msg login-input ms-2 d-flex"
                    message={errors.firstName.message}
                  />
                </div>
              )}
            </div>
            <div className="mb-3 form-group">
              <label htmlFor="lastName" className="form-label text-uppercase">
                {t('lastName')}
              </label>
              <input
                type="text"
                placeholder={t('lastName')}
                className={`form-control form-control-dark shadow-none ${
                  errors?.lastName ? 'error-input' : ''
                }`}
                id="affiliate_code"
                name="lastName"
                required={false}
                {...register('lastName')}
                aria-describedby=""
                maxLength={50}
              />
              {errors && errors.lastName && (
                <div className="error-container">
                  <ErrorMessage
                    className="color-red text-danger error-msg login-input ms-2 d-flex"
                    message={errors.lastName.message}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="full">
            <div className="mb-3 form-group">
              <PhoneInputField
                label={t('phone')}
                name={t('phone')}
                id={t('phone')}
                control={control}
                theme="dark"
                // rules={{ required: 'Phone number is required' }}
              />
            </div>
          </div>

          <div className="full">
            <div className="mb-3 form-group">
              <label htmlFor="email" className="form-label text-uppercase">
                {t('email')}
              </label>{' '}
              <span className="color-red">*</span>
              <input
                type="email"
                placeholder={t('emailPlaceholder')}
                className={`form-control form-control-dark shadow-none ${
                  errors?.email ? 'error-input' : ''
                }`}
                id="email"
                aria-describedby=""
                name="email"
                maxLength={50}
                {...register('email')}
              />
              {errors && errors.email && (
                <div className="error-container">
                  <ErrorMessage
                    className="color-red text-danger error-msg login-input ms-2 d-flex"
                    message={errors.email.message}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="full">
            <div className="mb-3 form-group">
              <label
                htmlFor="user_password"
                className="form-label text-uppercase"
              >
                {t('signupPassword')}
              </label>{' '}
              <span className="color-red">*</span>
              <input
                type={showPassword.showPass ? 'text' : 'password'}
                placeholder={t('********')}
                className={`form-control form-control-dark shadow-none ${
                  errors?.password ? 'error-input' : ''
                }`}
                id="user_password"
                name="password"
                maxLength={50}
                {...register('password')}
              />
              <a
                onClick={() =>
                  setShowPassword((prevState) => ({
                    ...prevState,
                    showPass: !prevState.showPass,
                  }))
                }
                className="show-password"
              >
                {!showPassword.showPass ? <EyeIcon /> : <EyeCloseIcon />}
              </a>
              {errors && errors.password && (
                <div className="error-container">
                  <ErrorMessage
                    className="color-red text-danger error-msg login-input ms-2 d-flex"
                    message={errors.password.message}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="full">
            <div className="mb-3 form-group">
              <label
                htmlFor="referred_code"
                className="form-label text-uppercase"
              >
                {t('ReferredCode')}
              </label>
              <input
                type="text"
                placeholder={t('ReferredCode')}
                className={`form-control form-control-dark shadow-none ${
                  errors?.affiliatedBy ? 'error-input' : ''
                }`}
                id="affiliate_code"
                name="affiliatedBy"
                required={false}
                {...register('affiliatedBy')}
                aria-describedby=""
                maxLength={50}
                defaultValue={query.get('affiliateCode')}
              />
              {errors && errors.affiliatedBy && (
                <div className="error-container">
                  <ErrorMessage
                    className="color-red text-danger error-msg login-input ms-2 d-flex"
                    message={errors.affiliatedBy.message}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="auth-fixed">
            <div className="auth-btn-wrap">
              <button
                type="submit"
                className="btn btn-secondary w-100"
                disabled={loading}
              >
                {loading ? (
                  <Loader variant={LOADER_HANDLER_TYPES.submit} />
                ) : (
                  <span>{t('startPlaying')}</span>
                )}
              </button>
            </div>
            <div className="auth-socail-wrap">
              <div className="auth-social-heading">
                <h6>
                  <span>{t('orContinueWith')}</span>
                </h6>
              </div>
            </div>
            <SocialLinks isLoginSignup />
          </div>
          <div
            className={`auth-mob-instructions d-block d-lg-none accordion pb-5 pb-md-0 ${
              registerTabActive && 'pb-add'
            }`}
          >
            <p>
              {t('usersConsent')}
              <LocalizedNavLink
                to="/site-info/terms-and-conditions"
                onClick={() => {
                  dispatch(setShowSignupPopup(false));
                  dispatch(setShowLoginPopup(false));
                }}
              >
                {t('termsOfService')}
              </LocalizedNavLink>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
