import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { GameImg } from 'pages/GameImage/index';
import Loader from 'components/ui-kit/Loader/index';
import { LOADER_HANDLER_TYPES } from 'constants/index';
import { scrollTopWithChat } from 'utils/helperFunctions.utils';
import FavouriteIcon from 'components/molecules/FavouriteIcon/index';
import { useTranslation } from 'react-i18next';
const AllGamesCard = (props) => {
  const {
    game,
    item,
    onGamePlayRedirect,
    isLoggedIn,
    favouriteGameHandler,
    gameId,
    actionLoading,
    isFavourite,
    searchPopup,
    cardClasses,
  } = props;

  const { t } = useTranslation();

  const trueRoutesAllowed = game?.CasinoGame
    ? game?.CasinoGame?.GameProvider?.isAllowed
    : game?.GameProvider?.isAllowed;

  return (
    <div
      key={item?.id || uuidv4()}
      className={`view-card ${cardClasses || ''}`}
    >
      <div className="theme-card-wrap">
        <div className="theme-card">
          <div
            className="casino-img-wrap"
            onClick={() => {
              trueRoutesAllowed && onGamePlayRedirect(item);
              scrollTopWithChat();
            }}
          >
            <GameImg imgSrc={item?.image} />
            <div className="card-overlay">
              {trueRoutesAllowed ? (
                <h4>{t('playNow')}</h4>
              ) : (
                <h4
                  style={{
                    fontSize: '12px',
                    textTransform: 'unset',
                    maxWidth: '90%',
                  }}
                >
                  {t('notAvailableInYourRegionWithVPN')}
                </h4>
              )}

              {trueRoutesAllowed
                ? isLoggedIn && (
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        favouriteGameHandler(e, item);
                      }}
                    >
                      {actionLoading && gameId === game?.id ? (
                        <Loader variant={LOADER_HANDLER_TYPES.submit} />
                      ) : (
                        <FavouriteIcon
                          searchPopup={searchPopup}
                          fill={
                            game?.isFavourite || isFavourite ? 'red' : 'none'
                          }
                        />
                      )}
                    </a>
                  )
                : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllGamesCard;
