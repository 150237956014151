import React from 'react';
import Tooltip from 'rc-tooltip';
import { TOOLTIP_VARIANT } from 'constants/index';

const CustomTooltip = (props) => {
  const { variant, message, children, showTooltip, style } = props;

  switch (variant) {
    case TOOLTIP_VARIANT.SIDE_BAR:
      return showTooltip ? (
        <Tooltip
          placement="right"
          trigger={['hover']}
          overlayStyle={{ fontSize: '0.7rem' }}
          overlay={<span>{message}</span>}
        >
          {children}
        </Tooltip>
      ) : (
        children
      );
    case TOOLTIP_VARIANT.SIDEBAR_BUTTON:
      return showTooltip ? (
        <Tooltip
          placement="bottom"
          trigger={['hover']}
          overlayStyle={{ fontSize: '0.7rem' }}
          overlay={<span>{message}</span>}
        >
          {children}
        </Tooltip>
      ) : (
        children
      );

    case TOOLTIP_VARIANT.TOP_RIGHT:
      return showTooltip ? (
        <Tooltip
          placement="topRight"
          trigger={['hover']}
          overlayStyle={{ fontSize: '0.7rem' }}
          overlay={<span>{message}</span>}
        >
          {children}
        </Tooltip>
      ) : (
        children
      );

    case TOOLTIP_VARIANT.DEFAULT:
      return showTooltip ? (
        <Tooltip
          placement="top"
          trigger={['hover']}
          overlayStyle={{ ...style, fontSize: '0.7rem' }}
          overlay={<span>{message}</span>}
        >
          {children}
        </Tooltip>
      ) : (
        children
      );

    default:
      break;
  }
};

export default CustomTooltip;
