import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import CustomRoutes from './routes';
import { LANGUAGES } from 'constants/index';
import { getLocalLanguage } from 'helpers/localstorage.helpers';

const Routes = () => {
  // Get default language
  const defaultLang = getLocalLanguage() || LANGUAGES[0].languageCode;

  return (
    <Router>
      <Route exact path="/">
        <Redirect to={`/${defaultLang}`} />
      </Route>
      <Route path="/:lang">
        <CustomRoutes />
      </Route>
    </Router>
  );
};

export default Routes;
