import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Community = () => {
  const { t } = useTranslation();

  return (
    <ul className="m-0">
      <li>
        <NavLink to="https://t.me/PlayClutchApp">{t('telegram')}</NavLink>
      </li>
      <li>
        <NavLink to="https://x.com/PlayClutchApp">{t('twitter')}</NavLink>
      </li>
      <li>
        <NavLink to="https://help.clutch.app/en/">{t('helpCentre')}</NavLink>
      </li>
    </ul>
  );
};

export default React.memo(Community);
