import { createSlice } from '@reduxjs/toolkit';
import { plus } from 'number-precision';
import {
  getLocalWalletSetting,
  setLocalWalletSetting,
} from 'helpers/localstorage.helpers';
import { userLogout, verifyEmail } from 'redux-thunk/thunk/auth.thunk';
import { getAllCurrency, getAllSiteinfo } from 'redux-thunk/thunk/gameSetting';
import {
  fetchUserInformation,
  getActiveJoiningBonus,
  getActiveReferralBonus,
  getUserPlacedBets,
  createWithdrawRequest,
  updateProfilePic,
  getReferralUsers,
  getUsersTipsTransactions,
  getUsersLoginLogs,
  generateQRCode,
  getAffiliateCommissionSettlement,
  getReferralState,
  getSignupLimit,
  getPaymentInitialize,
} from 'redux-thunk/thunk/user.thunk';

const initialState = {
  user: null,
  selectedWallet: null,
  redirectToAfterLogin: '',
  transactions: {
    rows: [],
    count: 0,
  },
  topBetTransactions: {
    rows: [],
    count: 0,
  },
  withdrawRequestData: null,
  qrCodeURl: null,
  showLoginOtp: false,
  siteInfo: null,
  // roundData: null,
  // showRoundHistoryModel: false,
  hideZeroCurrency: getLocalWalletSetting()?.hideZeroCurrency || false,
  displayFiatCurrency: getLocalWalletSetting()?.displayFiatCurrency || false,
  selectedFiat: getLocalWalletSetting()?.selectedFiat || 'USD',
  activeJoiningBonus: null,
  activeReferralBonus: null,
  emailVerificationError: null,
  emailVerificationErrorLoading: null,
  referralUsers: null,
  currencies: null,
  serverSeedHashes: null,
  creditQueue: [], // ONLY USED FOR PLINKO GAME TO DISPLAY DELAY IN CREDIT AND DEBIT AMOUNT
  tipsData: null,
  loginLogs: null,
  qrCodeData: null,
  affiliateCommissions: null,
  referralState: null,
  userLimit: false,
  paymentInitialize: {},
};
const {
  actions: {
    setUserData,
    setQrcodeUrl,
    setSelectedWallet,
    setRedirectToAfterLogin,
    setAfterResetPassword,
    setTransactions,
    showLoginOtpHandle,
    setSiteInfo,
    setWalletSetting,
    setTopBetTransactions,
    setWalletData,
    referralUsers,
    setCreditQueue,
    reflectCreditQueueAmount,
    tipsData,
    qrCodeData,
    updateKycData,
    setReferralState,
    setAffiliateCommissions,
    setUserRank,
  },
  reducer,
} = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setWalletData: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    setUserData: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    setQrcodeUrl: (state, action) => ({
      ...state,
      qrCodeURl: action.payload,
    }),
    setSelectedWallet: (state, action) => ({
      ...state,
      selectedWallet: action.payload,
    }),
    setRedirectToAfterLogin: (state, action) => ({
      ...state,
      redirectToAfterLogin: action.payload,
    }),
    setAfterResetPassword: (state, action) => ({
      ...state,
      afterResetPassword: action.payload,
    }),
    showLoginOtpHandle: (state, action) => ({
      ...state,
      showLoginOtp: action.payload,
    }),
    setTransactions: (state, action) => ({
      ...state,
      transactions: action.payload,
    }),
    setTopBetTransactions: (state, action) => ({
      ...state,
      topBetTransactions: action.payload,
    }),
    setSiteInfo: (state, action) => ({
      ...state,
      siteInfo: action.payload,
    }),
    setReferralState: (state, action) => {
      return {
        ...state,
        referralState: { ...state.referralState, ...action.payload },
      };
    },
    setAffiliateCommissions: (state, action) => {
      return {
        ...state,
        affiliateCommissions: {
          ...state?.affiliateCommissions,
          rows: state?.affiliateCommissions?.rows?.map((data) => ({
            ...data,
            status: 'completed',
          })),
        },
      };
    },
    setWalletSetting: (state, action) => {
      setLocalWalletSetting({
        hideZeroCurrency: action.payload.hideZero,
        displayFiatCurrency: action.payload.displayFiat,
        selectedFiat: action.payload.selectedFiatCurrency,
      });
      return {
        ...state,
        hideZeroCurrency: action.payload.hideZero,
        displayFiatCurrency: action.payload.displayFiat,
        selectedFiat: action.payload.selectedFiatCurrency,
      };
    },
    setCreditQueue: (state, action) => ({
      ...state,
      creditQueue: [...state.creditQueue, action.payload],
    }),
    reflectCreditQueueAmount: (state, action) => {
      if (state.creditQueue?.length > 0) {
        return {
          ...state,
          selectedWallet: {
            ...state.creditQueue[0],
            amount: plus(
              +state.selectedWallet.amount,
              +state.creditQueue[0]?.betInfo?.winningAmount
            ),
          },
          creditQueue: [...state.creditQueue].slice(1),
        };
      }
    },
    updateKycData: (state, action) => {
      const currentLevel = action?.payload?.verificationLevel;
      const currentLevelData = state.user?.kycVerification?.filter(
        (el) => el.verificationLevel === currentLevel
      );
      if (
        state.user.kycVerification?.length === 0 ||
        currentLevelData?.length === 0
      ) {
        return {
          ...state,
          user: {
            ...state.user,
            kycVerification: [...state.user.kycVerification, action.payload],
          },
        };
      } else {
        return {
          ...state,
          user: {
            ...state.user,
            kycVerification: state.user.kycVerification?.map((el) => {
              if (el.verificationLevel === currentLevel) {
                return {
                  ...el,
                  ...action.payload,
                };
              }
              return el;
            }),
          },
        };
      }
    },
    setUserRank: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          userRank: action.payload,
          nextRankingLevels: state.user.nextRankingLevels?.filter(
            (rank) => rank?.id !== action?.payload?.id
          ),
          totalWager: action?.payload?.totalWager,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInformation.fulfilled, (state, action) => {
        return {
          ...state,
          user: action.payload,
          serverSeedHashes: action.payload.serverSeedHash,
          selectedWallet:
            action.payload.wallets.find((ele) => ele.primary) ||
            action.payload.wallets[0],
        };
      })
      .addCase(getAllSiteinfo.fulfilled, (state, action) => {
        return {
          ...state,
          siteInfo: action.payload?.cms,
        };
      })
      .addCase(updateProfilePic.fulfilled, (state, action) => {
        return {
          ...state,
          user: { ...state.user, profileImageUrl: action.payload.location },
        };
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(getUserPlacedBets.fulfilled, (state, action) => {
        return {
          ...state,
          transactions: action.payload,
        };
      })
      .addCase(createWithdrawRequest.fulfilled, (state, action) => {
        return {
          ...state,
          withdrawRequestData: action.payload,
        };
      })
      .addCase(verifyEmail.pending, (state, action) => {
        return {
          ...state,
          emailVerificationErrorLoading: true,
        };
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        return {
          ...state,
          emailVerificationErrorLoading: false,
        };
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        return {
          ...state,
          emailVerificationError: action.payload,
          emailVerificationErrorLoading: false,
        };
      })
      .addCase(getActiveJoiningBonus.fulfilled, (state, action) => {
        return {
          ...state,
          activeJoiningBonus: action.payload,
        };
      })
      .addCase(getActiveReferralBonus.fulfilled, (state, action) => {
        return {
          ...state,
          activeReferralBonus: action.payload,
        };
      })
      .addCase(getReferralUsers.fulfilled, (state, action) => {
        return {
          ...state,
          referralUsers: action.payload?.affiliateUsers,
        };
      })
      .addCase(getAllCurrency.fulfilled, (state, action) => {
        return {
          ...state,
          currencies: action.payload,
        };
      })
      .addCase(getUsersTipsTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          tipsData: action.payload,
        };
      })
      .addCase(getUsersLoginLogs.fulfilled, (state, action) => {
        return {
          ...state,
          loginLogs: action.payload,
        };
      })
      .addCase(generateQRCode.fulfilled, (state, action) => {
        return {
          ...state,
          qrCodeData: action.payload,
        };
      })
      .addCase(getAffiliateCommissionSettlement.fulfilled, (state, action) => {
        return {
          ...state,
          affiliateCommissions: action.payload,
        };
      })
      .addCase(getReferralState.fulfilled, (state, action) => {
        return {
          ...state,
          referralState: action.payload,
        };
      })
      .addCase(getSignupLimit.fulfilled, (state, action) => {
        return {
          ...state,
          signupLimit: action.payload,
        };
      })
      // PAYMENT IQ
      .addCase(getPaymentInitialize.fulfilled, (state, action) => {
        return {
          ...state,
          paymentInitialize: action.payload,
        };
      });
  },
});

export default reducer;
export {
  setUserData,
  setSelectedWallet,
  setRedirectToAfterLogin,
  setAfterResetPassword,
  setTransactions,
  setQrcodeUrl,
  showLoginOtpHandle,
  setSiteInfo,
  setWalletSetting,
  setTopBetTransactions,
  setWalletData,
  referralUsers,
  setCreditQueue,
  reflectCreditQueueAmount,
  tipsData,
  qrCodeData,
  updateKycData,
  setReferralState,
  setAffiliateCommissions,
  setUserRank,
};
