import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { upperCase } from 'lodash';
import { setShowSignupPopup } from 'redux-thunk/redux/slices/gameSetting.slice';
import {
  ecoFutures,
  ecoSports,
  ecoCasino,
} from 'components/ui-kit/Icons/png/index';
import { ROLLER_COASTER, ROUTE_PATHS } from 'constants/index';
import { ClutchEcosystemIcon } from 'components/ui-kit/Icons/svg/index';
import ContentHeader from 'components/ui-kit/ContentHeader/index';
import CasinoCardSlider from 'components/ui-kit/CasinoCardSlider';
import { useLoginBeforeRedirect } from 'hooks/useLoginBeforeRedirect';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
const ClutchEcosystem = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useLocalizedNavigate();
  const { loginData } = useSelector((state) => state.auth);
  const { handleLoginBeforeRedirect } = useLoginBeforeRedirect();

  const handleClickRegister = () => {
    if (!loginData?.accessToken) {
      dispatch(setShowSignupPopup(true));
    }
  };

  const clutchEcoSystemItems = useMemo(
    () => [
      {
        cardClassName: 'casino-card-darkBlue',
        text: `${t('betOn')}<br /> 150+<span class='text-gradient-one'>${t(
          'sports'
        )}</span>`,
        subText: t('ecosystemUnbeatableOdds'),
        image: ecoSports,
        linkText: t('startBetting'),
        isTextGradient: false,
        isTextNewLine: true,
        to: ROUTE_PATHS.SPORTS_BOOK,
        textSize: 'big',
      },
      {
        cardClassName: 'casino-card-sea',
        text: `10,000+<br /><span class='text-gradient-two'>${t(
          'casino'
        )}</span>${t('games')}`,
        subText: t('ecosystemJoinTheFun'),
        image: ecoCasino,
        linkText: t('playNow'),
        isTextGradient: false,
        isTextNewLine: true,
        to: ROUTE_PATHS.CASINO,
        textSize: 'big',
      },
      {
        cardClassName: 'casino-card-purple',
        text: `1,000x<br /> ${upperCase(
          t('crypto')
        )}<span class='text-gradient-three'>${t('futures')}</span>`,
        subText: t('ecosystemTrade'),
        image: ecoFutures,
        linkText: t('startTrading'),
        isTextGradient: false,
        isTextNewLine: true,
        to: `${ROUTE_PATHS.CRYPTO}/${ROLLER_COASTER.id}`,
        textSize: 'big',
        onClick: (e) => {
          e.preventDefault();
          if (!loginData?.accessToken) {
            handleLoginBeforeRedirect(
              `${ROUTE_PATHS.CRYPTO}/${ROLLER_COASTER.id}`
            );
          } else {
            push(`${ROUTE_PATHS.CRYPTO}/${ROLLER_COASTER.id}`);
          }
        },
      },
    ],
    [loginData?.accessToken]
  );

  return (
    <section className="pb-4">
      <ContentHeader
        title={t('ecosystem')}
        subtitle={!loginData?.accessToken ? t('registerNow') : ''}
        Icon={ClutchEcosystemIcon}
        subtitleOnClickHandler={handleClickRegister}
        id="clutchEcoSystem"
      />
      <div className="slide-wrap">
        <CasinoCardSlider items={clutchEcoSystemItems} id="clutchEcoSystem" />
      </div>
    </section>
  );
};

export default React.memo(ClutchEcosystem);
