import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { rewardsVisa } from 'components/ui-kit/Icons/png/index';
import {
  RewardsApplepayIcon,
  RewardsGpayIcon,
  RewardsMastercardIcon,
  RewardsSamsungpayIcon,
} from 'components/ui-kit/Icons/svg/index';
import { usePreLogin } from 'hooks/usePreLogin';
import { useMoonPay } from '../../../../hooks/useMoonPay';
import { useTranslation } from 'react-i18next';

const PaymentSection = ({ className, redirectPath }) => {
  const { t } = useTranslation();
  const { showMoonPayWidget } = useMoonPay();
  const { loginData } = useSelector((state) => state.auth);
  const { handleRegister } = usePreLogin({ path: redirectPath });

  return (
    <section className={`crypto-pay-section ${className}`}>
      <div className="theme-card-wrap">
        <div className="theme-card d-md-flex align-items-center justify-content-between">
          <h3 className="my-0">{t('needCryptoWeGotYou')}</h3>
          <div className="payment-options">
            <NavLink to="#">
              <RewardsMastercardIcon style={{ width: '30px' }} />
            </NavLink>
            <NavLink to="#">
              <img src={rewardsVisa} alt="Visa" />
            </NavLink>
            <NavLink to="#">
              <RewardsApplepayIcon />
            </NavLink>
            <NavLink to="#">
              <RewardsSamsungpayIcon />
            </NavLink>
            <NavLink to="#">
              <RewardsGpayIcon />
            </NavLink>
          </div>
          {!loginData?.accessToken ? (
            <button
              type="button"
              className="btn btn-secondary text-nowrap"
              onClick={handleRegister}
            >
              Sign Up
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-secondary text-nowrap"
              onClick={showMoonPayWidget}
            >
              {t('buyCrypto')}
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default React.memo(PaymentSection);
