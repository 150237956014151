import React from 'react';
import { startCase } from 'lodash';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  CASINO_TABS,
  GAME_CATEGORY,
  ROUTE_PATHS,
  TOOLTIP_VARIANT,
} from 'constants/index';
import { setShowAllFavGames } from 'redux-thunk/redux/slices/games.slice';
import { setShowSideNav } from 'redux-thunk/redux/slices/settings.slice';
import {
  setGameCategory,
  setProvider,
  setSearchGame,
  setTab,
} from 'redux-thunk/redux/slices/lobby.slice';
import CustomTooltip from 'components/ui-kit/Tooltip/index';
import { heart, providersImg } from 'components/ui-kit/Icons/png/index';
import { CasinoMenuIcon } from 'components/ui-kit/Icons/svg/index';
import { LocalizedNavLink } from 'components/common/LocalizedLinks';

const CasinoSection = (props) => {
  const { showCasinoOptions, setShowCasinoOptions } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { loginData } = useSelector((state) => state.auth);
  const { gameCategory, tab } = useSelector((state) => state.lobby);
  const { casinoCategoriesList, showAllFavGames } = useSelector(
    (state) => state.games
  );
  const { showSideNav } = useSelector((state) => state?.settings);

  const visibleCategories = casinoCategoriesList?.filter(
    (category) => category.isVisible
  );

  const langCode = window.location.pathname.substring(0, 3);
  const purePathname = window.location.pathname.replace(langCode, '');
  return (
    <>
      <li>
        <LocalizedNavLink
          to={ROUTE_PATHS.CASINO}
          activeClassName={
            (purePathname === ROUTE_PATHS.CASINO ||
              gameCategory === CASINO_TABS.FAVOURITE ||
              purePathname.match(`${ROUTE_PATHS.CASINO}/group`)) &&
            tab !== CASINO_TABS.ORIGINALS
              ? 'active'
              : ''
          }
          onClick={() => {
            showAllFavGames && dispatch(setShowAllFavGames(false));
            dispatch(setTab(CASINO_TABS.LOBBY));
            dispatch(setProvider(''));
            dispatch(setSearchGame(''));
            dispatch(setGameCategory(''));
            isMobile && dispatch(setShowSideNav(!showSideNav));
          }}
        >
          <CustomTooltip
            variant={
              showSideNav ? TOOLTIP_VARIANT.TOP_RIGHT : TOOLTIP_VARIANT.SIDE_BAR
            }
            message={t('casino')}
            showTooltip={!showSideNav}
          >
            <div className="menu-left d-flex align-items-center">
              <div className="menu-icon">
                <CasinoMenuIcon />
              </div>
              <p className="m-0">{t('casino')}</p>
            </div>
          </CustomTooltip>
          <span
            className="menu-arrow"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowCasinoOptions((prev) => !prev);
            }}
          >
            <i
              className={`fa fa-caret-${showCasinoOptions ? 'up' : 'down'}`}
              aria-hidden="true"
            />
          </span>
        </LocalizedNavLink>
        {showCasinoOptions && showSideNav && (
          <ul className="submenu-wrap">
            {loginData?.accessToken && (
              <li>
                <LocalizedNavLink
                  to={ROUTE_PATHS.CASINO}
                  activeClassName={
                    gameCategory === GAME_CATEGORY.FAVOURITE ? 'active' : ''
                  }
                  onClick={(e) => {
                    dispatch(setTab(CASINO_TABS.GAME_SEARCH));
                    dispatch(setShowAllFavGames(true));
                  }}
                >
                  <img src={heart} alt="favourites-icon" />
                  <span>{t('favourites')}</span>
                </LocalizedNavLink>
              </li>
            )}
            {visibleCategories?.map((item) => (
              <li
                key={uuidv4()}
                className={
                  pathname ===
                  `${ROUTE_PATHS.CASINO}/group/${item?.gameCategory}`
                    ? 'active'
                    : ''
                }
              >
                <LocalizedNavLink
                  to={`${ROUTE_PATHS.CASINO}/group/${item?.gameCategory}`}
                  onClick={(e) => {
                    showAllFavGames && dispatch(setShowAllFavGames(false));
                    dispatch(setTab(CASINO_TABS.GAME_SEARCH));
                    dispatch(setGameCategory(item?.gameCategory));
                    dispatch(setProvider(''));
                  }}
                >
                  <img src={item?.image} alt={item?.gameCategory} />
                  <span>
                    {isMobile
                      ? startCase(item?.gameCategory)
                      : item?.gameCategory?.length > 14
                      ? `${startCase(item?.gameCategory.substring(0, 14))}...`
                      : startCase(item?.gameCategory)}
                  </span>
                </LocalizedNavLink>
              </li>
            ))}
            <li
              key={uuidv4()}
              className={pathname === ROUTE_PATHS.PROVIDERS ? 'active' : ''}
            >
              <LocalizedNavLink to={ROUTE_PATHS.PROVIDERS}>
                <img src={providersImg} alt="providersImg" />
                <span>{t('providers')}</span>
              </LocalizedNavLink>
            </li>
          </ul>
        )}
      </li>
      {showCasinoOptions && <hr className="theme-divider" />}
    </>
  );
};

export default React.memo(CasinoSection);
